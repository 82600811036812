import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { Link } from 'react-router-dom';

import TopHeader from '../layout/header';
import UserFeedback from './UserFeedback';
import { getUserFeedbacks } from '../../store/actions/UserActions';
import { API_URL } from '../../constants/config';
import isEmpty from '../../validation/is-empty';

class UserResponse extends Component {
  state = {
    form: {},
    isPasswordUpdated: {},
    showPastSurvey: true,
    showModal: false,
    assessmentId: '',
    feedbacks: [],
    feedbacksCalled: false
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.feedbacks.length && props.user.id && !state.feedbacksCalled) {
      props.getUserFeedbacks(props.user.id);
      return { feedbacksCalled: true };
    }

    if (props.feedbacks !== state.feedbacks) {
      const uniqIds = [
        ...new Set(props.feedbacks.map(result => result.uniqId))
      ];

      const dividedFeedbacks = [];
      uniqIds.map(id => {
        const assessmentData = props.feedbacks.filter(
          result => result.uniqId === id
        );
        //remove result of continue later
        if (assessmentData[0].testCount === assessmentData.length) {
          dividedFeedbacks.push(assessmentData);
        }
      });

      return {
        feedbacks: dividedFeedbacks
      };
    }

    return null;
  }

  onChange = e => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  onSubmit = e => {
    e.preventDefault();

    this.props.changePassword({
      ...this.state.form,
      userId: this.props.user.id
    });
  };

  showModal = e => {
    e.preventDefault();

    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  showResponseModal = (e, assessmentId) => {
    //this.props.getUserFeedback(userId, surveyId);
    this.setState({ showModal: true, assessmentId });
  };

  renderModal = () => {
    return (
      <div className="modal fade show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="question-heading">Past Survey Response</h6>
              <button type="button" className="close" onClick={this.hideModal}>
                &times;
              </button>
            </div>

            <div className="modal-body">
              <Tabs
                onSelect={(index, label) => console.log(label + ' selected')}
              >
                {this.state.feedbacks.length &&
                  this.state.feedbacks[this.state.assessmentId].map(
                    feedback => (
                      <Tab label={feedback.Test.name} key={feedback.id}>
                        <br />
                        <div className="modal-question-header">
                          <div className="magin-15">
                            <div className="row">
                              <div className="col-sm-8 col-6 answer-heading">
                                Answer
                              </div>
                              <div className="col-sm-4 col-6 answer-heading">
                                Count
                              </div>
                            </div>
                          </div>
                          {JSON.parse(feedback.answersCount).map(
                            (answer, index) => (
                              <UserFeedback
                                key={index}
                                first={answer.option}
                                second={answer.optionCount}
                              />
                            )
                          )}
                        </div>
                        <hr />
                        <div className="question">
                          <div className="magin-15">
                            <div className="row">
                              <div className="col-sm-8 col-6 answer-heading">
                                Question
                              </div>
                              <div className="col-sm-4 col-6 answer-heading">
                                Answer
                              </div>
                            </div>
                          </div>
                          {JSON.parse(feedback.answersAttempt).map(
                            (answer, index) => (
                              <UserFeedback
                                key={index}
                                first={answer.question}
                                second={answer.option}
                              />
                            )
                          )}
                        </div>
                      </Tab>
                    )
                  )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSurveyListCard = (survey, assessmentId) => {
    if (!survey) return;
    return (
      <li key={assessmentId} className="thumb1">
        <div className="mb-15">
          <div className="category-list dash-box">
            <div className="left-img">
              <h3>{survey.testCategory ? survey.testCategory : survey.name}</h3>
              {!survey.testCategory ? (
                <img
                  src={API_URL + survey.imagePath}
                  alt="Survey name"
                  className="surveyImage"
                  onClick={null}
                />
              ) : (
                <img
                  src="./images/golf_test.jpeg"
                  alt="Survey name"
                  className="surveyImage"
                  onClick={null}
                />
              )}
            </div>
            <div className="right-side">
              <Link
                to={`/result/${this.state.feedbacks[assessmentId][0].uniqId}`}
                className="dash-btn btn"
              >
                View Result
              </Link>
              <span
                onClick={e => this.showResponseModal(e, assessmentId)}
                className="dash-btn"
              >
                View Response
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  };

  render() {
    const { feedbacks } = this.state;

    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              <div className="row">
                {!isEmpty(feedbacks) ? (
                  <ul className="thumb-list">
                    {feedbacks.map((feedbackObj, index) =>
                      this.renderSurveyListCard(feedbackObj[0].Test, index)
                    )}
                  </ul>
                ) : (
                  <div className="alert alert-light" role="alert">
                    No records availble. Please give any survey first.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.showModal === true && this.renderModal()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  feedbacks: state.user.feedbacks
});

export default connect(
  mapStateToProps,
  { getUserFeedbacks }
)(UserResponse);
