import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import SignUp from '../components/user/SignUp';
import Login from '../components/user/Login';
import UserList from '../components/user_list';
import AdminKeyPage from '../components/admin_key_page';
import AdminSettingsPage from '../components/admin_settings_page';
import UserDashboard from '../components/user/UserDashboard';
import UserAssessment from '../components/user_assessment/UserAssessment';
import SurveyResponse from '../components/user_assessment/SurveyResponse';
import UserProfile from '../components/user/UserProfile';
import UserResponse from '../components/user/UserResponse';
import ForgotPassword from '../components/user/ForgotPassword';
import ResetPassword from '../components/user/ResetPassword';
import EnrolledUsers from '../components/admin_dashboard';
import Assessment from '../components/assessment';
import AssessmentQuestion from '../components/assessment_question';
import LearningContent from '../components/learning_content';
import Enquiry from '../components/enquiry';
import NotFound from '../components/not_found';

import PrivateRoute from '../components/common/PrivateRoute';

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={SignUp} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute
          roleId={1}
          exact
          path="/dashboard"
          component={UserDashboard}
        />
        <PrivateRoute
          roleId={1}
          exact
          path="/assessment/:surveyId"
          component={UserAssessment}
        />
        <PrivateRoute
          roleId={1}
          exact
          path="/result/:uniqId"
          component={SurveyResponse}
        />
        <PrivateRoute
          roleId={1}
          exact
          path="/profile"
          component={UserProfile}
        />
        <PrivateRoute
          exact
          roleId={1}
          path="/past-survey-results"
          component={UserResponse}
        />
        <Route
          exact
          path="/reset-password/:id/:resetToken"
          component={ResetPassword}
        />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <PrivateRoute
          exact
          path="/admin/dashboard"
          roleId={2}
          component={Assessment}
        />
        <PrivateRoute
          exact
          path="/admin/assessment"
          roleId={2}
          component={Assessment}
        />
        <PrivateRoute
          exact
          roleId={2}
          path="/admin/enrolled-users/:surveyId"
          component={EnrolledUsers}
        />
        <PrivateRoute
          exact
          roleId={2}
          path="/admin/assessment-questions/:surveyId"
          component={AssessmentQuestion}
        />
        <PrivateRoute
          exact
          roleId={2}
          path="/admin/learning-content"
          component={LearningContent}
        />
        <PrivateRoute
          exact
          roleId={2}
          path="/admin/user-list"
          component={UserList}
        />
        <PrivateRoute
          exact
          roleId={2}
          path="/admin/key"
          component={AdminKeyPage}
        />
        <PrivateRoute
          exact
          roleId={2}
          path="/admin/settings"
          component={AdminSettingsPage}
        />
        <Route exact path="/contact-us" component={Enquiry} />
        <Route path="" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
