import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const redirect = () => (window.location.href = '/login');

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  const computeRenderProps = props => {
    if (user.isAuthenticated && user.user.roleId !== rest.roleId) {
      return <Redirect to="/" />;
    }
    return user.isAuthenticated === true ? (
      <Component {...props} />
    ) : (
      redirect()
    );
  };

  return <Route {...rest} render={computeRenderProps} />;
};

PrivateRoute.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(PrivateRoute);
