export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_USER = 'GET_USER';
export const EMPTY_FETCHED_USER = 'EMPTY_FETCHED_USER';
export const GET_USERS = 'GET_USERS';
export const UPDATE_FETCHED_USERS = 'UPDATE_FETCHED_USERS';
export const GET_USERS_BY_SURVEY = 'GET_USERS_BY_SURVEY';
export const GET_USER_FEEDBACK = 'GET_USER_FEEDBACK';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_AUTH_TRUE = 'SET_AUTH_TRUE';
