import isEmpty from '../../validation/is-empty';

import {
  SET_CURRENT_USER,
  GET_USERS_BY_SURVEY,
  GET_USER_FEEDBACK,
  CHANGE_PASSWORD,
  SET_AUTH_TRUE,
  GET_USERS,
  GET_USER,
  UPDATE_FETCHED_USERS,
  EMPTY_FETCHED_USER
} from '../../constants/user';

const initialState = {
  isAuthenticated: false,
  isPasswordUpdated: {},
  user: {},
  userList: [],
  feedbacks: [],
  users: [],
  fetchedUser: {}
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: { ...state.user, ...action.payload }
      };

    case SET_AUTH_TRUE:
      return {
        ...state,
        isAuthenticated: true,
        user: { ...state.user, roleId: action.payload.roleId }
      };

    case GET_USERS_BY_SURVEY:
      return {
        ...state,
        userList: action.payload
      };

    case GET_USER_FEEDBACK:
      return {
        ...state,
        feedbacks: action.payload.data
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        isPasswordUpdated: action.payload
      };

    case GET_USERS:
      return {
        ...state,
        users: action.payload
      };

    case GET_USER:
      return {
        ...state,
        fetchedUser: action.payload
      };

    case UPDATE_FETCHED_USERS:
      const users = [...state.users];
      users[users.findIndex(user => user.id === action.payload.id)] =
        action.payload;

      return {
        ...state,
        users
      };

    case EMPTY_FETCHED_USER:
      return {
        ...state,
        fetchedUser: {}
      };

    default:
      return state;
  }
};

export default user;
