import { combineReducers } from 'redux';

import admin from './adminReducer';
import error from './errorReducer';
import success from './successReducer';
// import assessment from './assessmentReducers';
import assessmentQuestion from './assessmentQuestionReducer';
import learningContent from './learningContentReducer';
import user from './userReducer';
import setting from './settingReducer';
import coupons from './couponReducer';
// import userAssessment from './userAssessmentReducer';

const reducers = combineReducers({
  admin,
  // assessment,
  error,
  success,
  assessmentQuestion,
  learningContent,
  user,
  setting,
  coupons
  // userAssessment
});

export default reducers;
