import axios from 'axios';

import {
  GET_COUPONS,
  UPDATE_COUPONS,
  DELETE_COUPON,
  EDIT_COUPON
} from '../../constants/coupons';
import { GET_ERROR, CLEAR_ERROR } from '../../constants/error';
import { GET_SUCCESS, CLEAR_SUCCESS } from '../../constants/success';

import { API_URL } from '../../constants/config';

// Get COUPONS
export const getCoupons = id => dispatch => {
  dispatch(clearError());
  if (id === undefined) {
    dispatch({ type: GET_SUCCESS, payload: {} });
    return dispatch({
      type: GET_COUPONS,
      payload: {}
    });
  }

  axios
    .get(API_URL + `private/coupons`)
    .then(res => {
      const { data } = res;
      dispatch({ type: GET_SUCCESS, payload: data.status });
      dispatch({ type: GET_COUPONS, payload: data });
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

export const updateCoupon = (updateData, id) => dispatch => {
  dispatch(clearError());
  dispatch(clearSuccess());
  if (id === undefined) {
    dispatch({ type: GET_SUCCESS, payload: {} });
    dispatch({ type: UPDATE_COUPONS, payload: {} });
    return dispatch({
      type: GET_COUPONS,
      payload: {}
    });
  }
  axios
    .patch(API_URL + `private/admin_coupons/${id}/edit`, updateData)
    .then(res => {

      dispatch({
        type: GET_SUCCESS,
        payload: res.data.status
      });
      dispatch({
        type: UPDATE_COUPONS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Delete Coupon
export const deleteCoupon = id => dispatch => {
  axios
    .delete(API_URL + `private/admin_coupons/${id}/delete`)
    .then(res => {
      dispatch({
        type: GET_SUCCESS,
        payload: res.data.status
      });
      dispatch({
        type: DELETE_COUPON,
        payload: res.data
      });
    }
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Edit Coupon
export const editCoupon = id => dispatch => {
  dispatch(clearError());
  dispatch(clearSuccess());
  if (id === undefined) {
    dispatch({ type: GET_SUCCESS, payload: {} });
    dispatch({ type: UPDATE_COUPONS, payload: {} });
    return dispatch({
      type: EDIT_COUPON,
      payload: {}
    });
  }
  axios
    .get(API_URL + `private/coupon_code/${id}`)
    .then(res => {
        dispatch({ type: UPDATE_COUPONS, payload: {} });
        dispatch({ type: EDIT_COUPON, payload: res.data });
      }
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Clear error
export const clearError = () => {
  return {
    type: CLEAR_ERROR
  };
};

// Clear success
export const clearSuccess = () => {
  return {
    type: CLEAR_SUCCESS
  };
};