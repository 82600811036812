import {
  ADD_SURVEY,
  UPDATE_SURVEY,
  GET_SURVEYS,
  GET_SURVEY,
  SURVEY_LOADING,
  DELETE_SURVEY
} from '../../constants/admin';

const initialState = {
  surveys: [],
  survey: {},
  loading: false
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case SURVEY_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_SURVEYS:
      return {
        ...state,
        surveys: action.payload.data,
        loading: false
      };
    case GET_SURVEY:
      return {
        ...state,
        survey: action.payload.data,
        loading: false
      };
    case ADD_SURVEY:
      return {
        ...state,
        surveys: [action.payload.data, ...state.surveys]
      };
    case UPDATE_SURVEY:
      return {
        ...state,
        surveys: state.surveys.map(survey =>
          survey.id === action.payload.data.id ? action.payload.data : survey
        )
      };

    case DELETE_SURVEY:
      return {
        ...state,
        surveys: state.surveys.filter(survey => survey.id !== action.payload)
      };
    default:
      return state;
  }
};

export default admin;
