import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
  SET_CURRENT_USER,
  // GET_USERS_BY_SURVEY,
  GET_USER_FEEDBACK,
  UPDATE_FETCHED_USERS,
  GET_USER,
  GET_USERS,
  SET_AUTH_TRUE,
  EMPTY_FETCHED_USER
} from '../../constants/user';
import { GET_ERROR, CLEAR_ERROR } from '../../constants/error';
import { GET_SUCCESS, CLEAR_SUCCESS } from '../../constants/success';

import { API_URL } from '../../constants/config';

// Register User
export const registerUser = (userData, successFn) => dispatch => {
  dispatch(clearError());
  axios
    .post(API_URL + 'public/users/register', userData)
    .then(() => {
      successFn();
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Get User
export const getUser = id => dispatch => {
  axios
    .get(API_URL + `private/users/${id}/item`)
    .then(res => {
      const { data } = res.data;

      dispatch({
        type: GET_USER,
        payload: data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Get Users
export const getUsers = () => dispatch => {
  axios
    .get(API_URL + `private/users/`)
    .then(res => {
      const { data } = res.data;

      dispatch({
        type: GET_USERS,
        payload: data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Login - Get User Token
export const loginUser = userData => dispatch => {
  dispatch(clearError());
  axios
    .post(API_URL + 'public/users/login', userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Update Fetched User
export const updateFetchedUser = (userData, id) => dispatch => {
  dispatch(clearError());
  dispatch(clearSuccess());
  axios
    .patch(`${API_URL}private/users/${id}/update`, userData)
    .then(res => {
      const { data } = res.data;

      dispatch({
        type: GET_SUCCESS,
        payload: res.data.status
      });

      dispatch({ type: UPDATE_FETCHED_USERS, payload: data });
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Update User
export const updateUser = (
  userData,
  id,
  currentUser,
  onReload,
  successFn
) => dispatch => {
  dispatch(clearError());
  dispatch(clearSuccess());
  axios
    .patch(`${API_URL}private/users/${id}/edit`, userData)
    .then(res => {
      const { data } = res.data;

      !onReload &&
        dispatch({
          type: GET_SUCCESS,
          payload: res.data.status
        });

      if (successFn) successFn();
      // Set current user
      dispatch(setCurrentUser(Object.assign(currentUser, data)));
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Set logged in user
export const setCurrentUser = userProfileDetail => {
  return {
    type: SET_CURRENT_USER,
    payload: userProfileDetail
  };
};

// Set user auth true
export const setAuthTrue = payload => ({
  type: SET_AUTH_TRUE,
  payload
});

// Log user out
export const logoutUser = history => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  // history.push('/login');
};

// Clear error
export const clearError = () => {
  return {
    type: CLEAR_ERROR
  };
};

// Clear success
export const clearSuccess = () => {
  return {
    type: CLEAR_SUCCESS
  };
};

export const getUserFeedbacksBySurvey = surveyId => dispatch => {
  if (surveyId === undefined) {
    return dispatch({
      type: GET_USER_FEEDBACK,
      payload: { data: {} }
    });
  }

  axios
    .get(API_URL + `private/user_assessments/${surveyId}/survey_results`)
    .then(res =>
      dispatch({
        type: GET_USER_FEEDBACK,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_USER_FEEDBACK,
        payload: err.response.data
      })
    );
};

export const getUserFeedbacks = userId => dispatch => {
  if (userId === undefined) {
    return dispatch({
      type: GET_USER_FEEDBACK,
      payload: { data: [] }
    });
  }

  axios
    .get(API_URL + `private/user_assessments/${userId}/user_results`)
    .then(res =>
      dispatch({
        type: GET_USER_FEEDBACK,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_USER_FEEDBACK,
        payload: err.response.data
      })
    );
};

//empty fetched user
export const emptyFetchedUser = () => dispatch =>
  dispatch({
    type: EMPTY_FETCHED_USER
  });
