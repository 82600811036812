//export const API_URL = 'http://localhost:5000/';
// export const API_URL = 'http://192.168.42.11:5001/';

// export const API_URL =
//   window.location.host === '192.168.42.11:82'
//     ? 'http://192.168.42.11:5000/'
//     : 'http://182.74.239.21:5000/';

//export const API_URL = 'https://18.217.206.236:6001/'; //old
export const API_URL = 'https://tlgl-api.ballflightuniversity.com:6001/'; //new on domain
