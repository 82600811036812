import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import SubsRedeemModal from '../common/SubsRedeemModal';

import TopHeader from '../layout/header';
import { getSurveysByUser } from '../../store/actions/AdminActions';

import { API_URL } from '../../constants/config';

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      subsRedeemModalBool: false,
      successMessage: false,
      suvreysCalled: false,
      unmodifiedSurveys: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user.id && !state.surveys.length && !state.suvreysCalled) {
      props.getSurveysByUser(props.user.id);
      return { suvreysCalled: true };
    }

    if (
      JSON.stringify(props.admin.surveys) !==
        JSON.stringify(state.unmodifiedSurveys) &&
      state.suvreysCalled
    ) {
      const { surveys } = props.admin;
      const modifiedSurveys = [];

      surveys.forEach(survey => {
        if (survey.testCategory) {
          if (!modifiedSurveys[survey.testCategory])
            modifiedSurveys[survey.testCategory] = [];
          modifiedSurveys[survey.testCategory].push(survey);

          return;
        }
        modifiedSurveys.push(survey);
      });

      return {
        surveys: modifiedSurveys,
        unmodifiedSurveys: props.admin.surveys
      };
    }

    return null;
  }

  enrollUser = async (e, id, enrollStatus) => {
    e.preventDefault();

    if (!enrollStatus) {
      Alert.info('Please give this assessment and check your results.', {
        position: 'bottom-left',
        effect: 'bouncyflip',
        timeout: 'none'
      });
    }

    setTimeout(() => this.props.history.push(`/assessment/${id}`), 2000);
  };

  showSuccessMessage = () => {
    this.setState({ subsRedeemModalBool: false, successMessage: true });
  };

  closeSuccessMessage = () => {
    this.setState({ successMessage: false });
  };

  closeAlert = () => {
    this.setState({ subsRedeemModalBool: false });
  };

  showAlert = () => {
    this.setState({ subsRedeemModalBool: true });
  };

  renderSurveyCards = surveyKey => {
    const survey = this.state.surveys[surveyKey];
    let btnValue;
    let onClickFn;

    const surveyId = survey.id || survey[0].id;

    const userStatus =
      survey.enrolled === undefined
        ? survey.some(survey => survey.enrolled)
        : survey.enrolled;

    switch (userStatus) {
      case true:
        btnValue = 'Launch';
        if (Array.isArray(survey))
          onClickFn = e => this.enrollUser(e, survey[0].id, survey[0].enrolled);
        else onClickFn = e => this.enrollUser(e, survey.id, survey.enrolled);
        break;
      case false:
        if (!this.props.user.testIds.includes(surveyId)) {
          btnValue = 'Purchase';
          onClickFn = () => this.setState({ subsRedeemModalBool: true });
          break;
        }
      default:
        btnValue = 'Enroll';
        if (Array.isArray(survey))
          onClickFn = e => this.enrollUser(e, survey[0].id, survey[0].enrolled);
        else onClickFn = e => this.enrollUser(e, survey.id, survey.enrolled);
    }

    if (Array.isArray(survey)) {
      return (
        <li className="thumb1" key={surveyId}>
          <div className="mb-15">
            <div className="category-list dash-box">
              <div className="left-img">
                {
                  <img
                    src="./images/golf_test.jpeg"
                    alt="Observent"
                    className="surveyImage"
                  />
                }
              </div>
              <div className="right-side">
                <h3>{survey[0].testCategory}</h3>
                <p>This is a combined test</p>
                <Link to="#" className="dash-btn" onClick={e => onClickFn(e)}>
                  {btnValue}
                </Link>
              </div>
            </div>
          </div>
        </li>
      );
    }
    return (
      <li className="thumb1" key={surveyId}>
        <div className="mb-15">
          <div className="category-list dash-box">
            <div className="left-img">
              <img
                src={API_URL + survey.imagePath}
                alt="Observent"
                className="surveyImage"
              />
            </div>
            <div className="right-side">
              <h3>{survey.name}</h3>
              <p>{survey.description}</p>
              <Link to="#" className="dash-btn" onClick={e => onClickFn(e)}>
                {btnValue}
              </Link>
            </div>
          </div>
        </div>
      </li>
    );
  };

  render() {
    const { surveys, subsRedeemModalBool, unmodifiedSurveys } = this.state;
    const subsRedeemModalProps = {
      surveys,
      unmodifiedSurveys,
      closeAlert: this.closeAlert,
      user: this.props.user,
      showSuccessMessage: this.showSuccessMessage
    };
    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              <h1 className="main-heading">
                Learning Content List (By Category)
              </h1>
              <div className="row">
                <ul className="thumb-list">
                  {Object.keys(surveys).map(surveyKey =>
                    this.renderSurveyCards(surveyKey)
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {subsRedeemModalBool && <SubsRedeemModal {...subsRedeemModalProps} />}
        {this.state.successMessage && (
          <SweetAlert
            success
            title="You have successfully redeem subscription."
            onConfirm={this.closeSuccessMessage}
          >
            Press OK to enjoy services.
          </SweetAlert>
        )}
        <Alert stack={{ limit: 1 }} />
      </>
    );
  }
}

UserDashboard.propTypes = {
  getSurveysByUser: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  admin: state.admin,
  user: state.user.user
});

export default connect(
  mapStateToProps,
  { getSurveysByUser }
)(UserDashboard);
