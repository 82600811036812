import React from 'react';

const UserFeedback = ({ first, second }) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-6 col-md-8 mb-10">{first}</div>
        <div className="col-xs-12 col-6 col-md-4 mb-10">{second}</div>
      </div>
    </>
  );
};

export default UserFeedback;
