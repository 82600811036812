import {
  ADD_LEARNING_CONTENT,
  UPDATE_LEARNING_CONTENT,
  GET_LEARNING_CONTENTS,
  GET_LEARNING_CONTENT,
  LEARNING_CONTENT_LOADING,
  DELETE_LEARNING_CONTENT
} from '../../constants/learning_content';

const initialState = {
  learningContents: [],
  learningContent: {},
  resultAnalysis: {},
  loading: false
};

const learningContent = (state = initialState, action) => {
  switch (action.type) {
    case LEARNING_CONTENT_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_LEARNING_CONTENTS:
      return {
        ...state,
        learningContents: action.payload.data,
        loading: false
      };
    case GET_LEARNING_CONTENT:
      return {
        ...state,
        learningContent: action.payload.data,
        resultAnalysis: action.payload.resultAnalysis
          ? action.payload.resultAnalysis
          : {},
        loading: false
      };
    case ADD_LEARNING_CONTENT:
      return {
        ...state,
        learningContents: [action.payload.data, ...state.learningContents]
      };
    case UPDATE_LEARNING_CONTENT:
      return {
        ...state,
        learningContents: state.learningContents.map(learningContent =>
          learningContent.id === action.payload.data.id
            ? action.payload.data
            : learningContent
        )
      };

    case DELETE_LEARNING_CONTENT:
      return {
        ...state,
        learningContents: state.learningContents.filter(
          learningContent => learningContent.id !== action.payload
        )
      };
    default:
      return state;
  }
};

export default learningContent;
