import React, { useRef, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { API_URL } from '../../constants/config';
import TopHeader from '../layout/header';
import { connect } from 'react-redux';
import { 
  getCoupons, 
  updateCoupon, 
  deleteCoupon, 
  editCoupon
} from '../../store/actions/CouponsActions';
import isEmpty from '../../validation/is-empty';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const AdminKey = (props) => {
  const [copySuccess, setCopySuccess] = useState('');
  const [adminKey, setAdminKey] = useState('');
  const textAreaRef = useRef(null);
  const [coupons, setCoupons] = useState({});
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState({});

  const copyToClipboard = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
    setCopySuccess('Copied');
  }

  const errorStyle = {
    color: 'red',
    fontSize: '13px',
  };

  const validate = (values) => {
    let errors = {};
    if ( values.percentage === undefined || values.percentage === "") {
      errors.percentage = 'Percentage is required.';
    } else if (values.percentage && !/^[1-9]?[0-9]{1}$|^100/.test(values.percentage)) {
      errors.percentage = 'Interger between 1-100 allowed.';
    }
    if (values.code === undefined || values.code === "") {
      errors.code = 'Coupon code is required.';
    }
    return errors;
  }

  const updateApiKey = async () => {
    try {
      const result = await axios.patch(API_URL + 'private/admin_key/1/edit', {
        key: createKey(19)
      });
      setAdminKey(result.data.data.key);
    } catch (err) {
      console.log(err.response.data);
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    setValues({ ...values, [event.target.name]:event.target.value});
  }

  const createCouponCode = (e) => {
    if (e) {
      e.preventDefault();
    }
    setErrors(validate(values));
    setIsSubmitting(true);
  }

  const addCodupon = useCallback(async () => {
    try {
      const result = await axios.post(API_URL + 'public/coupon_code/new', values);
      setIsSubmitting(false);
      if(result.data.status === true){
        setValues({percentage:"", code:""});
        setMessage('coupon has been added successfully');
        setTimeout(() => setMessage(''), 5000);
        props.getCoupons(true);
      } else if (result.data.status === 400){
        setErrors({cError:result.data.message});
        setTimeout(() => setErrors({cError:''}), 5000);
      }
    } catch (err) {
      console.log(err.response.data);
    }
  }, [values]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting && !isEditing) {
      addCodupon();
    } else if (Object.keys(errors).length === 0 && isEditing && isSubmitting) {
      props.updateCoupon(values, isEditing);
      setIsSubmitting(false);
      setIsEditing(false);
      setValues({});
    } else {
      setIsSubmitting(false);
    }
  }, [errors, isSubmitting, addCodupon, isEditing]);

  const createKey = (length) => {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    axios
      .get(API_URL + 'private/admin_key/1')
      .then(({ data: { data } }) => setAdminKey(data.key))
      .catch(err => console.log(err.response.data));
  }, []);

  const updateCouponStatus = (couponId, status) => {
    props.updateCoupon({ isActive: status }, couponId);
  };
  const handleDeleteCoupon = (couponId) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this ?',
      buttons: [
        { label: 'Yes',
          onClick: () => props.deleteCoupon(couponId)
        },
        { label: 'No',
          onClick: () => {}
        }
      ]
    });
  };
  const handleEditCoupon = (couponId) => {
    props.editCoupon(couponId);
  };

  useEffect(() => {
    if (
      Object.keys(props.updateCoupons).length > 0 &&
      'status' in props.updateCoupons
      ) {
      if (
        props.updateCoupons.status 
      ) {
        setMessage('Coupon code has been updated successfully.');
        setTimeout(() => setMessage(''), 5000);
        setIsSubmitting(false);
        setIsEditing(false);
        props.getCoupons();
      }
    }
    if (
      Object.keys(props.deleteCoupons).length > 0 &&
      'status' in props.deleteCoupons
      ) {
      if (
        props.deleteCoupons.status 
      ) {
        setMessage('Coupon code has been deleted successfully.');
        setTimeout(() => setMessage(''), 5000);
        props.getCoupons();
       }
    }
    if (
      Object.keys(props.editedCoupon).length > 0 &&
      'status' in props.editedCoupon
      ) {
      if (
        props.editedCoupon.status 
      ) {
        setIsEditing(props.editedCoupon.data.id);
        setValues({ ...values, percentage:props.editedCoupon.data.percentage, code:props.editedCoupon.data.code});
        props.editCoupon();
       }
    }
    props.getCoupons(true);
  }, [props.updateCoupons, props.deleteCoupons, props.editedCoupon]);

  useEffect(() => {
    if (
      Object.keys(props.allCoupons).length > 0 &&
      'status' in props.allCoupons
    ) {
      if (
        props.allCoupons.status &&
        props.allCoupons.data.length > 0
      ) {
        setCoupons(props.allCoupons.data);
        props.getCoupons();
       }
    }
    if (props.errors) {
      setErrors({cError:props.errors});
      setTimeout(() => setErrors({cError:''}), 5000);
    }
  }, [props.allCoupons, props.errors]);

  const renderCoupon = coupon => {
    return (
      <tr key={coupon.id}>
        <td>{coupon.code}</td>
        <td>{coupon.percentage}</td>
        <td>
          <div className="category-icons">
            <span
              className={coupon.isActive ? 'delete' : 'group'}
              title={coupon.isActive ? 'Deactivate' : 'Activate'}
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Change Status"
              onClick={e => updateCouponStatus(coupon.id, !coupon.isActive)}
            >
              <i
                className={`fa ${
                  coupon.isActive ? 'fa-toggle-on' : 'fa-toggle-off'
                }`}
              />
            </span>
            <span className="group" title="Edit" onClick={() => handleEditCoupon(coupon.id)}>
              <i className="fa fa-edit" />
            </span>
            <span className="delete" title="Delete" onClick={() => handleDeleteCoupon(coupon.id)}>
              <i className="fa fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <TopHeader />
      <div className="wrapper">
        <div className="container">
        {message && (<div className="alert alert-success" role="alert">
          {message}
        </div>)}
          <h1 className="main-heading">Api Key</h1>
          <div className="row">
          <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-8">
              <div className="card bg-light text-dark">
                <div className="card-body">
                  <form>
                    <input
                      className="admin-key-text"
                      ref={textAreaRef}
                      value={adminKey}
                      readOnly
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="col-sm-4 mt-3">
              {document.queryCommandSupported('copy') && (
                <span className="group pointer mr-3" onClick={copyToClipboard}>
                  <i className="fa fa-clipboard fa-3x" />
                </span>
              )}
              <span className="group pointer mr-2" onClick={updateApiKey}>
                <i className="fa fa-refresh fa-3x" />
              </span>

              {copySuccess}
            </div>
          </div>
          <br />
          <br />
          
          <h1 className="main-heading">Coupon Code</h1>
          {errors.cError && ( <p style={errorStyle}>{errors.cError}</p>)}
            <form  onSubmit={createCouponCode}>
              <div className="row pb-5">
                <div className="col-sm-8">
                  <div className="card bg-light text-dark">
                    <div className="card-body">
                      <input
                        className="form-control"
                        name="code" placeholder="Coupon code"
                        value={values.code || ''} onChange={handleChange}
                      />{errors.code && ( <p style={errorStyle}>{errors.code}</p>)}
                      <br></br>
                      <input className="form-control" name="percentage" placeholder="Percentage (eg: 85)" value={values.percentage || ''} onChange={handleChange}/>
                      {errors.percentage && ( <p style={errorStyle}>{errors.percentage}</p>)}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 mt-3">
                  <span className="group pointer mr-2" onClick={createCouponCode}>
                    <i className="fa fa-plus-square fa-3x" />
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-6">
            <div className="row">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="25%">Coupon Code</th>
                    <th width="17%">Percent</th>
                    <th width="50%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(coupons) &&
                    coupons.map(coupon => renderCoupon(coupon))}
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  allCoupons: state.coupons.coupons,
  updateCoupons: state.coupons.updatedCoupon,
  deleteCoupons: state.coupons.deletedCoupon,
  editedCoupon: state.coupons.editedCoupon,
  errors: state.error
});

export default connect(
  mapStateToProps,
  {
    getCoupons,
    updateCoupon,
    deleteCoupon,
    editCoupon
  }
)(AdminKey);