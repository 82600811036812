import React from 'react';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';

import setAuthToken from './utils/setAuthToken';
import {
  logoutUser,
  updateUser,
  setAuthTrue
} from './store/actions/UserActions';
import store from './store';
import Router from './routes';

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = '/login';
  }
  store.dispatch(setAuthTrue({ roleId: decoded.roleId }));

  // Set user and isAuthenticated
  store.dispatch(updateUser({}, decoded.id, decoded, true));
}

const App = () => {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};

export default App;
