import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import NavBar from './Navbar';
import { logoutUser } from '../../../store/actions/UserActions';
import { getSettings } from '../../../store/actions/SettingActions';

const TopHeader = ({ history, logoutUser, user, ...props }) => {
  const [settings, setSettings] = useState(new Map());

  useEffect(() => {
    props.getSettings(true);
  }, []);

  useEffect(() => {
    if (
      Object.keys(props.allSettings).length > 0 &&
      "status" in props.allSettings
    ) {
      if (
        props.allSettings.status &&
        props.allSettings.data.length > 0
      ) {
        setSettings(new Map([
          ...props.allSettings.data.map(item => [item.slug, item.value])
        ]));
        props.getSettings();
      }
    }
  }, [props.allSettings])

  return (
    <>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              {/* <div className="logo">
                <img src="/images/logonew.png" alt="Survey Application" />
              </div> */}
            </div>
            <div className="col-sm-6 text-right mt-1">
            <div className="social-mobile">
              <div className="socila-links-header">
                <ul>
                  <li>
                    <a
                      href={ settings.get('facebook_link') && settings.get('facebook_link') !== null ? settings.get('facebook_link') : 'https://www.facebook.com/Thinklowgolow' }
                      className="facebook"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={ settings.get('instagram_link') && settings.get('instagram_link') !== null ? settings.get('instagram_link') : 'https://www.instagram.com/think_low_go_low/?hl=en' }
                      className="instagram"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>

              {!user.isAuthenticated ? (
                <div className="user">
                  <Link to="/sign-up">Sign Up</Link>{' '}
                  <span className="stick">|</span>
                  <Link to="/login" className="text-success">
                    Sign In
                  </Link>
                </div>
              ) : (
                <span className="ml-4">
                  <button
                    onClick={() => logoutUser(history)}
                    // className="btn btn-outline-secondary"
                    className="btn d-none d-lg-inline-block btn-outline-secondary"
                  > Logout </button>
                </span>
              )}
            </div>
            </div>
          </div>
        </div>
      </header>
      <NavBar />
    </>
  );
};

TopHeader.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  allSettings: state.setting.settings,
});

export default connect(
  mapStateToProps,
  { logoutUser, getSettings }
)(withRouter(TopHeader));
