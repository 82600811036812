import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import PaypalBtn from './Paypal';

import { updateUser } from '../../store/actions/UserActions';
import { getSettings } from "../../store/actions/SettingActions";
import { API_URL } from '../../constants/config';

class SubsRedeemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedPurchases: new Map(
        Object.keys(props.surveys).map(id =>
          Array.isArray(props.surveys[id])
            ? [props.surveys[id][0].id, false]
            : [props.surveys[id].id, false]
        )
      ),
      surveyPrices: new Map([
        ...props.unmodifiedSurveys.map(item => [item.id, item.price])
      ]),
      allPurchased: false,
      couponCode: '',
      error: '',
      allSettings: new Map(),
      finalPrice: 0,
      errorMessage: "",
      successMessage: "",
      finalPercentage: 0
    };
    this.props.getSettings(true);
  }

  onPurchaseChange = e => {
    const item = parseInt(e.target.name);
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedPurchases: prevState.checkedPurchases.set(item, isChecked),
      //couponCode: '',
      allPurchased: false
    }));
    this.setState({}, () => {
      this.setState({
        finalPrice: this.handleFinalPrice()
      });
    });
  };

  onAllPurchase = e => {
    const isChecked = e.target.checked;
    this.setState(
      { 
        allPurchased: isChecked
      },
      () => {
        this.setState({
          checkedPurchases: new Map(),
          finalPrice: this.handleFinalPrice()
        });
      }
    );
  };

  handleFinalPrice = () => {
    let price = this.state.allPurchased
      ? this.state.allSettings.get("raspberry_students_price") &&
        this.state.allSettings.get("raspberry_students_price") !== null
        ? Number(this.state.allSettings.get("raspberry_students_price"))
        : 200
      : Array.from(this.state.checkedPurchases)
        .filter(val => val[1])
        .reduce(
          (total, [property]) =>
            total + this.state.surveyPrices.get(property),
          0
        );
    return this.state.finalPercentage > 0
      ? +(((100 - this.state.finalPercentage) / 100) * price).toFixed(2)
      : +price.toFixed(2);
  };

  onPaymentSuccess = () => {
    let testIds = Object.keys(this.props.surveys).reduce(
      (acc, surveyKey) =>
        acc.concat(
          Array.isArray(this.props.surveys[surveyKey])
            ? this.props.surveys[surveyKey].map(item => item.id)
            : this.props.surveys[surveyKey].id
        ),
      []
    );

    if (!this.state.allPurchased) {
      const optIds = Array.from(this.state.checkedPurchases)
        .filter(val => val[1])
        .map(val => val[0]);

      testIds = optIds.reduce((acc, val) => {
        const test = Object.values(this.props.surveys).find(
          test =>
            Array.isArray(test) && test.find(subTest => subTest.id === val)
        );
        if (test) {
          return acc.concat(
            test.reduce((acc, subTest) => acc.concat(subTest.id), [])
          );
        } else return acc;
      }, optIds);
    }

    const payload = {
      testIds: [
        ...new Set([...testIds, ...JSON.parse(this.props.user.testIds)])
      ]
    };

    this.props.updateUser(
      payload,
      this.props.user.id,
      this.props.user,
      false,
      this.props.showSuccessMessage
    );
  };

  handleCouponRemove = e => {
    e.preventDefault();
    this.setState(
      {
        finalPercentage: 0,
        couponCode: ""
      },
      () => {
        this.setState({
          finalPrice: this.handleFinalPrice()
        });
      }
    );
    this.setState({ successMessage: "Coupon removed successfully" });
    setTimeout(() => this.setState({ successMessage: "" }), 4000);
  };

  handleCouponApply = e => {
    e.preventDefault();
    if (this.state.couponCode) {
      const couponCodeBody = { code: this.state.couponCode };
      axios
        .post(API_URL + "public/coupon_code/validate-code", couponCodeBody)
        .then(res => {
          if (Object.values(res.data.data).length > 0) {
            this.setState(
              { finalPercentage: Object.values(res.data.data)[0].percentage },
              () => {
                this.setState({
                  finalPrice:
                    this.state.finalPercentage === 100
                      ? 0
                      : this.handleFinalPrice()
                });
              }
            );
          }
          this.setState({ successMessage: "Coupon applied successfully" });
        })
        .catch(err => {
          this.setState({
            successMessage: "",
            finalPercentage: 0,
            errorMessage: err.response.data.message
          });
          setTimeout(() => this.setState({ errorMessage: "" }), 6000);
        });
    } else {
      this.setState({ errorMessage: "Please Enter Coupon Code." });
      setTimeout(() => this.setState({ errorMessage: "" }), 4000);
    }
  };

  onPaymentCancel = data => {
    this.setState({ error: 'Payment has got cancelled. Try again.' });
    console.log('The payment was cancelled!', data);
  };

  onPaymentError = err => {
    this.setState({ error: 'Something went wrong. Try again.' });
    console.log('Error!', err);
  };

  onSubsRedeemSubmit = e => {
    e.preventDefault();

    if (!(Array.from(this.state.checkedPurchases).filter(val => val[1]).length > 0 || this.state.allPurchased)) {
      this.setState({
        error: "Please select a survey before proceeding."
      });
      setTimeout(() => this.setState({ error: "" }), 5000);
      return;
    }
    this.setState({ errorMessage: "", error: "" });

    this.onPaymentSuccess();

    // if (this.state.couponCode) {
    //   const couponCodeBody = { code: this.state.couponCode };
    //   const payload = {
    //     testIds: Object.keys(this.props.surveys).reduce(
    //       (acc, surveyKey) =>
    //         acc.concat(
    //           Array.isArray(this.props.surveys[surveyKey])
    //             ? this.props.surveys[surveyKey].map(item => item.id)
    //             : this.props.surveys[surveyKey].id
    //         ),
    //       []
    //     )
    //   };
    //   axios
    //     .post(API_URL + 'public/coupon_code/validate-code', couponCodeBody)
    //     .then(() => {
    //       this.props.updateUser(
    //         payload,
    //         this.props.user.id,
    //         this.props.user,
    //         false,
    //         this.props.showSuccessMessage
    //       );
    //     })
    //     .catch(() =>
    //       this.setState({ error: 'Coupon Code is not valid.' }, () =>
    //         setTimeout(() => this.setState({ error: '' }), 5000)
    //       )
    //     );
    // }
  };

  render() {
    const errorStyle = {
      color: "red",
      fontSize: "13px"
    };
    const successStyle = {
      color: "green",
      fontSize: "13px"
    };
    const { error } = this.state;
    const { surveys } = this.props;
    let unpurchasedSurveyCount = 0;

    //set css style according to device size
    let deviceStyleObj = {};
    if (window.outerWidth >= 980) {
      deviceStyleObj = {
        minwidth: '55%',
        width: '55%'
      };
    } else {
      deviceStyleObj = {
        width: 'auto'
      };
    }

    if (
      Object.keys(this.props.allSettings).length > 0 &&
      "status" in this.props.allSettings
    ) {
      if (
        this.props.allSettings.status &&
        this.props.allSettings.data.length > 0
      ) {
        this.setState({
          ...this.state,
          allSettings: new Map([
            ...this.props.allSettings.data.map(item => [item.slug, item.value])
          ])
        });
        this.props.getSettings();
      }
    }
    return (
      <SweetAlert
        custom
        type="custom"
        showConfirm={false}
        showCancel
        cancelBtnBsStyle="danger"
        title="Select a purchase or enter coupon code."
        onCancel={this.props.closeAlert}
        onConfirm={() => null}
        style={{
          ...{
            maxWidth: '478px',
            minHeight: '350px',
            left: '15px ',
            right: '15px',
            margin: '0 auto',
            top: '30px'
          },
          ...deviceStyleObj
        }}
      >
        {error && error !== 'secondformincomplete' && (
          <div className="alert alert-danger subsModalAlert" role="alert">
            {error}
          </div>
        )}
        
        <form className="row subsModalForm" noValidate onSubmit={this.onSubsRedeemSubmit} >
          <div className="col-sm-6">
            <div className="form-check">
            <p className="check-head">Non Raspberry Academy Students</p>
              {surveys.length &&
                Object.keys(surveys).map(survey => {
                  // if (index > 0)
                  //   if (
                  //     surveys[index].testCategory &&
                  //     surveys[index].testCategory ===
                  //       surveys[index - 1].testCategory
                  //   )
                  //     return;
                  survey = surveys[survey];
                  if (Array.isArray(survey)) {
                    survey = survey[0];
                    survey.name = survey.testCategory;
                  }
                  if (this.props.user.testIds.includes(survey.id)) return;
                  unpurchasedSurveyCount++;
                  return (
                    <div className="form-check subsModalCheck" key={survey.id}>
                      <input
                        type="checkbox"
                        name={survey.id}
                        id={survey.id}
                        onChange={this.onPurchaseChange}
                        className="form-check-input"
                        checked={this.state.checkedPurchases.get(survey.id)}
                      />
                      <label htmlFor={survey.id} className="form-check-label">
                        {survey.name} (${survey.price})
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-check">
              <p className="check-head">Raspberry Academy Students</p>
              {surveys.length && unpurchasedSurveyCount > 1 && (
                <div
                  className="form-check subsModalCheck"
                  key={surveys[surveys.length - 1].id + 1}
                >
                  <input
                    type="checkbox"
                    id="allSelect"
                    name="allSelect"
                    className="form-check-input "
                    onChange={this.onAllPurchase}
                    checked={this.state.allPurchased}
                  />
                  <label className="form-check-label" htmlFor="allSelect">
                    All 
                      {/* ($ {this.state.allSettings.get(
                        "raspberry_students_price"
                      ) &&
                      this.state.allSettings.get(
                        "raspberry_students_price"
                      ) !== null
                        ? this.state.allSettings.get(
                            "raspberry_students_price"
                          ) : 200}
                      ) */}
                  </label>
                  {this.state.allPurchased && (
                    <div className="form-group alt-msg col-sm-12">
                    <p>Please enter the coupon code provided by your instructor.</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

            {this.state.errorMessage && (
              <div className="form-group coupn-alt-error col-sm-12">
                <p> {this.state.errorMessage} </p>
              </div>
            )}
            {this.state.successMessage && (
              <div className="form-group coupn-alt-success col-sm-12">
                <p> {this.state.successMessage} </p>
              </div>
            )}
          <div className="col-sm-12 coupon-code-block">
            <div className="form-group md-input subsModalInputFlex">
              <input
                className="md-form-control"
                type="text"
                name="couponCode"
                readOnly={this.state.finalPercentage > 0}
                value={this.state.couponCode}
                onChange={e =>
                  this.setState({
                    couponCode: e.target.value,
                    //checkedPurchases: new Map(),
                    //allPurchased: false
                  })
                }
              />
              <span className="highlight" />
              <i className="fa fa-ticket custome-envelope" aria-hidden="true" />
              <label>Have Coupon Code ? </label>
            </div>
            <div className="code-btn">
              {this.state.finalPercentage > 0 ? (
                <button
                  className="btn custom-btn cpn-aply"
                  onClick={this.handleCouponRemove}
                > REMOVE</button>
              ) : (
                <button
                  className="btn custom-btn cpn-aply"
                  onClick={this.handleCouponApply}
                > APPLY </button>
              )}
            </div>
          </div>
          {/* <div className="col-sm-2 checklist">
            <p>OR</p>
          </div> */}
          <div className="col-sm-6">
            {this.state.finalPrice > 0 ? (
              <p className="fnl-prc"><b>Final Price: ${this.state.finalPrice}</b></p>
            ) : ( "")}
          </div>
          <div className="col-sm-6">
            <div className="login-btm paypal-btn-parent">
              <button
                type="submit"
                className="btn custom-btn submit-btn"
                //disabled={this.state.checkedPurchases.size > 0}
              >
                Submit
              </button>
              {((Array.from(this.state.checkedPurchases).filter(val => val[1])
                .length > 0 ||
                this.state.allPurchased) ) &&
                this.state.finalPrice > 0 && (
                <PaypalBtn
                  onSuccess={this.onPaymentSuccess}
                  onError={this.onPaymentError}
                  onCancel={this.onPaymentCancel}
                  total={this.state.finalPrice}
                />
              )}
            </div>
          </div>
        </form>
      </SweetAlert>
    );
  }
}

SubsRedeemModal.propTypes = {
  surveys: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  closeAlert: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  allSettings: state.setting.settings,
  error: state.error
});

export default connect(mapStateToProps, { updateUser, getSettings })(
  withRouter(SubsRedeemModal)
);
