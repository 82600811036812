import {
  GET_COUPONS,
  UPDATE_COUPONS,
  DELETE_COUPON,
  EDIT_COUPON
} from '../../constants/coupons';

const initialState = {
  coupons: {},
  updatedCoupon:{},
  deletedCoupon:{},
  editedCoupon: {}
};

const coupons = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUPONS:
      return {
        ...state,
        coupons: action.payload
      };

    case UPDATE_COUPONS:
      return {
        ...state,
        updatedCoupon: action.payload
      };
    case DELETE_COUPON:
        return {
          ...state,
          deletedCoupon: action.payload
        };
    case EDIT_COUPON:
        return {
          ...state,
          editedCoupon: action.payload
        };

    default:
      return state;
  }
};

export default coupons;
