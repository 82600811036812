import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logoutUser } from '../../../store/actions/UserActions';

class NavBar extends Component {
  state = {
    toggleState: ''
  };

  adminLinks = (logoutUser) => (
    <ul className={`navbar-nav ${this.state.toggleState} mr-auto`}>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname ===
            '/admin/dashboard' && 'active'}`}
          to="/admin/dashboard"
        > Dashboard </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname ===
            '/admin/learning-content' && 'active'}`}
          to={{
            pathname: '/admin/learning-content',
            state: {
              prevPath: this.props.location.pathname,
              prevParam: this.props.match.params.surveyId
            }
          }}
        > Personality Content </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname ===
            '/admin/user-list' && 'active'}`}
          to="/admin/user-list"
        > User List </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname === '/admin/key' &&
            'active'}`}
          to="/admin/key"
        > Key </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname === '/admin/settings' &&
            'active'}`}
          to="/admin/settings"
        > Settings </Link>
      </li>
      <li className="nav-item d-block d-lg-none">
        <Link onClick={() => logoutUser()}
          to="#" className={`nav-link logout-btn`}>
        Logout </Link>
      </li>
    </ul>
  );

  userLinks = (logoutUser) => (
    <ul className={`navbar-nav ${this.state.toggleState} mr-auto`}>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname === '/dashboard' &&
            'active'}`}
          to="/dashboard"
        > Dashboard </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname === '/profile' &&
            'active'}`}
          to="/profile"
        > Profile </Link>
      </li>
      <li className="nav-item">
        <Link
          className={`nav-link ${window.location.pathname ===
            '/past-survey-results' && 'active'}`}
          to="/past-survey-results"
        > Past Survey Results </Link>
      </li>
      <li className="nav-item d-block d-lg-none">
        <Link onClick={() => logoutUser()}
          to="#" className={`nav-link logout-btn`}>
         Logout </Link>
      </li>
    </ul>
  );

  render() {
    return (
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() =>
              this.setState({
                toggleState: this.state.toggleState === '' ? 'active' : ''
              })
            }
          >
            <span className="navbar-toggler-icon">
              <i className="fa fa-navicon" />
            </span>
          </button>
          {this.props.user.user.roleId === 2
            ? this.adminLinks(this.props.logoutUser)
            : this.userLinks(this.props.logoutUser)}
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          />
        </div>
      </nav>
    );
  }
}

NavBar.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(NavBar));
