import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import TopHeader from '../layout/header';
import { getSingleSurvey } from '../../store/actions/AdminActions';
import {
  addQuestion,
  updateQuestion,
  getQuestionsBySingleTest,
  getQuestion,
  deleteQuestion
} from '../../store/actions/AssessmentQuestionActions';
import { clearError } from '../../store/actions/ErrorActions';
import isEmpty from '../../validation/is-empty';

class AssessmentQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        question: '',
        questionType: 'Normal',
        TestId: this.props.match.params.surveyId,
        QuestionOptions: [
          {
            option: '',
            marks: 0,
            isActive: true
          },
          {
            option: '',
            marks: 0,
            isActive: true
          },
          {
            option: '',
            marks: 0,
            isActive: true
          },
          {
            option: '',
            marks: 0,
            isActive: true
          }
        ]
      },
      error: '',
      showModal: false,
      questions: [],
      question: {}
    };
  }

  componentDidMount() {
    if (this.props.match.params.surveyId) {
      this.props.getSingleSurvey(this.props.match.params.surveyId);
    }
    this.props.getQuestionsBySingleTest(this.props.match.params.surveyId);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.error !== state.error) {
      return {
        error: props.error
      };
    }

    if (props.assessmentQuestion.adminQuestions !== state.questions) {
      return {
        questions: props.assessmentQuestion.adminQuestions,
        showModal: false
      };
    }

    if (props.assessmentQuestion.question !== state.question) {
      return {
        form: !isEmpty(props.assessmentQuestion.question)
          ? {
              question: props.assessmentQuestion.question.question,
              questionType: props.assessmentQuestion.question.questionType,
              TestId: props.assessmentQuestion.question.TestId,
              QuestionOptions: props.assessmentQuestion.question.QuestionOptions.map(
                option => ({
                  option: option.option,
                  marks: option.marks,
                  isActive: option.isActive
                })
              )
            }
          : state.form,
        question: props.assessmentQuestion.question
      };
    }

    return null;
  }

  getInputAttributes = (name, placeholder) => {
    return {
      name: name,
      onChange: this.onChange,
      value: this.state.form[name] || '',
      error: this.state.error[name] || '',
      placeholder: placeholder
    };
  };

  onChange = e => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [e.target.name]: e.target.value }
    });
  };

  onOptionChange = (e, optionIndex, checkboxBool) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        QuestionOptions: this.state.form.QuestionOptions.map((option, index) =>
          index === optionIndex
            ? {
                ...option,
                [e.target.name]: checkboxBool
                  ? e.target.checked
                  : e.target.value
              }
            : option
        )
      }
    });
  };

  onSubmit = async (e, id) => {
    e.preventDefault();
    const { form } = this.state;

    isEmpty(this.state.question)
      ? this.props.addQuestion(form)
      : this.props.updateQuestion(form, id);
  };

  deleteQuestion = (e, id) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteQuestion(id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  showModal = (e, id) => {
    e.preventDefault();
    this.props.getQuestion(id);
    this.props.clearError();
    this.setState({
      showModal: true,
      form: {
        ...this.state.form,
        question: '',
        questionType: 'Normal',
        TestId: this.props.match.params.surveyId,
        QuestionOptions: [
          {
            option: '',
            marks: 0,
            isActive: true
          },
          {
            option: '',
            marks: 0,
            isActive: true
          },
          {
            option: '',
            marks: 0,
            isActive: true
          },
          {
            option: '',
            marks: 0,
            isActive: true
          }
        ]
      }
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  renderModal = () => {
    return (
      <div className="modal fade show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="question-heading">
                {isEmpty(this.state.question)
                  ? 'Add Question'
                  : 'Update Question'}
              </h6>
              <button type="button" className="close" onClick={this.hideModal}>
                &times;
              </button>
            </div>

            {this.state.error && (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            )}

            <div className="modal-body">
              <form
                noValidate
                onSubmit={e => this.onSubmit(e, this.state.question.id)}
              >
                <div className="form-group question question-desc">
                  <label htmlFor="exampleInputEmail1" className="question mb-5">
                    Question Description
                  </label>
                  <br />
                  <input
                    type="text"
                    className="form-control font"
                    {...this.getInputAttributes(
                      'question',
                      'Enter the question'
                    )}
                  />
                </div>

                <div className="form-group question">
                  <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10 text-lg-right">
                      <label htmlFor="exampleInputEmail1">Option 1</label>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 col-md-6 mb-10">
                      <input
                        type="text"
                        className="form-control container1 container2"
                        name="option"
                        onChange={e => this.onOptionChange(e, 0)}
                        value={this.state.form.QuestionOptions[0].option}
                        placeholder="Option 1"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-md-2 mb-10">
                      <input
                        type="number"
                        className="form-control container1 container2"
                        name="marks"
                        onChange={e => this.onOptionChange(e, 0)}
                        value={this.state.form.QuestionOptions[0].marks}
                        placeholder="Marks"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-md-2 mb-10">
                      <label className="container1 small">
                        Is Visible
                        <input
                          type="checkbox"
                          name="isActive"
                          onChange={e => this.onOptionChange(e, 0, true)}
                          checked={this.state.form.QuestionOptions[0].isActive}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group question">
                  <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10 text-lg-right">
                      <label htmlFor="exampleInputEmail1">Option 2</label>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 col-md-6 mb-10">
                      <input
                        type="text"
                        className="form-control container1 container2"
                        name="option"
                        onChange={e => this.onOptionChange(e, 1)}
                        value={this.state.form.QuestionOptions[1].option}
                        placeholder="Option 2"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10">
                      <input
                        type="number"
                        className="form-control container1 container2"
                        name="marks"
                        onChange={e => this.onOptionChange(e, 1)}
                        value={this.state.form.QuestionOptions[1].marks}
                        placeholder="Marks"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10">
                      <label className="container1 small">
                        Is Visible
                        <input
                          type="checkbox"
                          name="isActive"
                          onChange={e => this.onOptionChange(e, 1, true)}
                          checked={this.state.form.QuestionOptions[1].isActive}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group question">
                  <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10 text-lg-right">
                      <label htmlFor="exampleInputEmail1">Option 3</label>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 mb-10">
                      <input
                        type="text"
                        className="form-control container1 container2"
                        name="option"
                        onChange={e => this.onOptionChange(e, 2)}
                        value={this.state.form.QuestionOptions[2].option}
                        placeholder="Option 3"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10">
                      <input
                        type="number"
                        className="form-control container1 container2"
                        name="marks"
                        onChange={e => this.onOptionChange(e, 2)}
                        value={this.state.form.QuestionOptions[2].marks}
                        placeholder="Marks"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10">
                      <label className="container1 small">
                        Is Visible
                        <input
                          type="checkbox"
                          name="isActive"
                          onChange={e => this.onOptionChange(e, 2, true)}
                          checked={this.state.form.QuestionOptions[2].isActive}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group question">
                  <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10 text-lg-right">
                      <label htmlFor="exampleInputEmail1">Option 4</label>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 mb-10">
                      <input
                        type="text"
                        className="form-control container1 container2"
                        name="option"
                        onChange={e => this.onOptionChange(e, 3)}
                        value={this.state.form.QuestionOptions[3].option}
                        placeholder="Option 4"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10">
                      <input
                        type="number"
                        className="form-control container1 container2"
                        name="marks"
                        onChange={e => this.onOptionChange(e, 3)}
                        value={this.state.form.QuestionOptions[3].marks}
                        placeholder="Marks"
                      />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 mb-10">
                      <label className="container1 small">
                        Is Visible
                        <input
                          type="checkbox"
                          name="isActive"
                          onChange={e => this.onOptionChange(e, 3, true)}
                          checked={this.state.form.QuestionOptions[3].isActive}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-btn">
                  <button className="next" type="submit">
                    {isEmpty(this.state.question)
                      ? 'Save Question '
                      : 'Update Question '}
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer" />
          </div>
        </div>
      </div>
    );
  };

  renderQuestionList = question => {
    return (
      <tr key={question.id}>
        <td>{question.question}</td>
        <td>
          <div className="category-icons">
            <span
              className="group"
              onClick={e => this.showModal(e, question.id)}
            >
              <i className="fa fa-edit" />
            </span>
            <span
              className="delete"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Delete"
              onClick={e => this.deleteQuestion(e, question.id)}
            >
              <i className="fa fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              <h1 className="main-heading">
                Question List - {this.props.admin.survey.name}{' '}
                <button
                  type="button"
                  className="btn custom-btn next add-ques"
                  onClick={this.showModal}
                >
                  Add Question
                </button>
              </h1>

              <div className="row">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th width="85%">Question Description</th>
                      <th width="15%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.questions.map(question =>
                      this.renderQuestionList(question)
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal === true && this.renderModal()}
      </>
    );
  }
}

AssessmentQuestion.propTypes = {
  getSingleSurvey: PropTypes.func.isRequired,
  addQuestion: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  getQuestionsBySingleTest: PropTypes.func.isRequired,
  getQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
  assessmentQuestion: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  error: state.error,
  admin: state.admin,
  assessmentQuestion: state.assessmentQuestion
});

export default connect(
  mapStateToProps,
  {
    getSingleSurvey,
    addQuestion,
    updateQuestion,
    getQuestionsBySingleTest,
    getQuestion,
    deleteQuestion,
    clearError
  }
)(AssessmentQuestion);
