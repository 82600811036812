import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';

import { API_URL } from '../../constants/config';

import TopHeader from '../layout/header';

import { getQuestions } from '../../store/actions/AssessmentQuestionActions';
import { updateUser } from '../../store/actions/UserActions';

class UserAssessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answersAttempt: {},
      questionIndex: 0,
      surveyQuestions: [],
      // currentTestIndex: 0,
      finalResultsArr: [],
      alertPoppedUp: true,
      testIds: []
    };
  }

  componentDidMount() {
    // this.props.getQuestions(this.props.match.params.surveyId);
  }

  componentWillUnmount() {
    this.props.getQuestions();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.user.id &&
      !state.surveyQuestions.length &&
      !state.suvreyQuestionsCalled
    ) {
      props.getQuestions(props.match.params.surveyId, props.user.id);

      return { suvreyQuestionsCalled: true };
    }

    if (
      state.surveyQuestions.length === 0 &&
      props.assessmentQuestion.questions.length
    ) {
      const testIds = [
        ...new Set(
          props.assessmentQuestion.questions.map(question => question.Test.id)
        )
      ];

      let questionsList = props.assessmentQuestion.questions;

      if (
        props.assessmentQuestion.answersAttempt &&
        props.assessmentQuestion.answersCount
      ) {
        // const answersAttemptArr = JSON.parse(
        //   props.assessmentQuestion.answersAttempt
        // );
        // const attemptedQuestionIds = answersAttemptArr.map(
        //   obj => obj.questionId
        // );
        // questionsList = questionsList.filter(
        //   question => !attemptedQuestionIds.includes(question.id)
        // );

        const remainingSurveyQuestions = JSON.parse(
          props.assessmentQuestion.surveyQuestions
        );
        questionsList = remainingSurveyQuestions;

        // if (!answersAttemptArr.length && props.assessmentQuestion.TestId) {
        //   testIds.splice(0, testIds.indexOf(props.assessmentQuestion.TestId));
        // } else {
        // const attemptedQuestionIds = answersAttemptArr.map(
        //   obj => obj.questionId
        // );

        //   let spliceIndex;
        //   questionsList.some((questionObj, index) => {
        //     if (
        //       attemptedQuestionIds[attemptedQuestionIds.length - 1] ===
        //       questionObj.id
        //     ) {
        //       spliceIndex = index;

        //       return true;
        //     }
        //   });

        //   if (spliceIndex) {
        //     questionsList.splice(0, spliceIndex + 1);
        //   }
        // }
      }

      // let dividedQuestionArr = [];
      // testIds.map((id, index) => {
      //   const testQuestions = questionsList.filter(
      //     question => question.Test.id === id
      //   );
      //   if (testQuestions.length) dividedQuestionArr.push(testQuestions);
      // });

      return {
        surveyQuestions: questionsList,
        testIds
      };
    }

    return null;
  }

  onNext = () => {
    // if (
    //   this.state.surveyQuestions[this.state.currentTestIndex].length - 1 ===
    //   this.state.questionIndex
    // ) {
    //   this.calculateResults(false);
    //   this.setState({
    //     questionIndex: 0,
    //     currentTestIndex: ++this.state.currentTestIndex
    //   });
    // } else {
    this.setState({
      questionIndex: ++this.state.questionIndex
    });
    // }
  };

  onPrev = () => {
    delete this.state.answersAttempt[
      this.state.surveyQuestions /*[this.state.currentTestIndex]*/[
        this.state.questionIndex
      ].id
    ];

    // if (this.state.currentTestIndex !== 0 && this.state.questionIndex === 0) {
    //   this.setState({
    //     currentTestIndex: --this.state.currentTestIndex,
    //     questionIndex:
    //       this.state.surveyQuestions[this.state.currentTestIndex].length - 1
    //   });
    // } else {
    this.setState({
      questionIndex: --this.state.questionIndex
    });
    // }
  };

  onChoose = (e, question, index) => {
    this.setState({
      answersAttempt: {
        ...this.state.answersAttempt,

        [question.id]: {
          ...this.state.answersAttempt[question.id],

          optionId: parseInt(e.target.name),

          option: question.QuestionOptions[index].option
        }
      }
    });
  };

  updateSurveyResponse = question => {
    this.setState({
      answersAttempt: {
        ...this.state.answersAttempt,

        [question.id]: {
          optionId: question.QuestionOptions[0].id,

          option: question.QuestionOptions[0].option,

          questionId: question.id,

          question: question.question
        }
      }
    });
  };

  saveForLater = e => {
    e.target.disabled = true;

    this.calculateResults(null, true);

    // axios
    //   .post(API_URL + `private/user_assessments`, this.state.finalResultsArr)
    //   .then(res => this.props.history.replace('/dashboard'))
    //   .catch(err => alert(err.response.data.message));
  };

  calculateResults = (e, saveForLater) => {
    if (!saveForLater) e.target.disabled = true;

    const { answersAttempt } = this.state;

    const testIds = [
      ...new Set(
        this.props.assessmentQuestion.questions.map(
          question => question.Test.id
        )
      )
    ].sort(function(a, b) {
      return a - b;
    });

    // let obtainedMarks, percentageObtained, maxMarks;
    if (saveForLater) {
      const Ids = Object.entries(answersAttempt);

      let surveyResponse = [];

      const optionSelected = {};

      for (const [questionId, answerAttempt] of Ids) {
        if (
          questionId == this.state.surveyQuestions[this.state.questionIndex].id
        ) {
          continue;
        }
        surveyResponse.push({
          optionId: answerAttempt.optionId,

          option: answerAttempt.option,

          questionId: answerAttempt.questionId,

          question: answerAttempt.question
        });

        optionSelected[answerAttempt.option] = isNaN(
          optionSelected[answerAttempt.option]
        )
          ? 1
          : parseInt(optionSelected[answerAttempt.option]) + 1;
      }

      let answersCount = Object.keys(optionSelected).map(option => ({
        option: option,
        optionCount: optionSelected[option]
      }));
      answersCount.map((answer, index) => {
        if (
          answer.option === surveyResponse[surveyResponse.length - 1].option
        ) {
          if (answersCount[index].optionCount > 1)
            answersCount[index].optionCount -= 1;
          else answersCount.splice(index, 1);
        }
      });

      const attemptedQuestionIds = surveyResponse.map(obj => obj.questionId);

      if (
        this.props.assessmentQuestion.answersAttempt &&
        this.props.assessmentQuestion.answersCount
      ) {
        const prevAnswersCount = JSON.parse(
          this.props.assessmentQuestion.answersCount
        );

        const toSliceIndexes = [];
        prevAnswersCount.map((prevAnswer, prevIndex) => {
          answersCount.map((answer, index) => {
            if (prevAnswer.option === answer.option) {
              answersCount[index].optionCount += prevAnswer.optionCount;
              toSliceIndexes.push(prevIndex);
            }
          });
        });

        for (let i = toSliceIndexes.length - 1; i >= 0; i--)
          prevAnswersCount.splice(toSliceIndexes[i], 1);

        answersCount = [...prevAnswersCount, ...answersCount];

        surveyResponse = [
          ...JSON.parse(this.props.assessmentQuestion.answersAttempt),
          ...surveyResponse
        ];
      }

      this.state.finalResultsArr.push({
        TestId: this.state.surveyQuestions[0].Test.id,
        UserId: this.props.user.id,
        answersAttempt: surveyResponse,
        answersCount,
        testCount: this.state.testIds.length,
        surveyQuestions: this.state.surveyQuestions.filter(
          question => !attemptedQuestionIds.includes(question.id)
        ),
        testIds
      });

      axios
        .post(API_URL + `private/user_assessments`, this.state.finalResultsArr)
        .then(res => this.props.history.replace('/dashboard'))
        .catch(err => alert(err.response.data.message));
    } else {
      testIds.map(testId => {
        let obtainedMarks, percentageObtained, maxMarks;
        const testQuestionIds = [];
        const testSurveyQuestions = this.props.assessmentQuestion.questions.filter(
          question => {
            if (question.Test.id === testId) {
              testQuestionIds.push(question.id);
              return true;
            } else {
              return false;
            }
          }
        );

        let testAnswersAttempt = {};

        for (const [questionId, answerAttempt] of Object.entries(
          answersAttempt
        )) {
          if (testQuestionIds.includes(parseInt(questionId))) {
            testAnswersAttempt[questionId] = answerAttempt;
          }
        }

        if (
          this.props.assessmentQuestion.answersAttempt &&
          this.props.assessmentQuestion.answersCount
        ) {
          const prevAnswersAttempt = JSON.parse(
            this.props.assessmentQuestion.answersAttempt
          ).filter(answerAttempt =>
            testQuestionIds.includes(answerAttempt.questionId)
          );

          const prevfilteredAnswersAttempt = {};

          prevAnswersAttempt.map(answerAttempt => {
            prevfilteredAnswersAttempt[answerAttempt.questionId] = {
              optionId: answerAttempt.optionId,

              option: answerAttempt.option,

              questionId: answerAttempt.questionId,

              question: answerAttempt.question
            };
          });

          testAnswersAttempt = {
            ...prevfilteredAnswersAttempt,
            ...testAnswersAttempt
          };
        }

        maxMarks = testSurveyQuestions.reduce(
          (total, question) =>
            Math.max(...question.QuestionOptions.map(option => option.marks)) +
            total,
          0
        );

        obtainedMarks = testSurveyQuestions.reduce((total, question) => {
          return (
            question.QuestionOptions.find(
              option => option.id === testAnswersAttempt[question.id].optionId
            ).marks + total
          );
        }, 0);

        percentageObtained = ((obtainedMarks / maxMarks) * 100).toFixed(2);

        const Ids = Object.entries(testAnswersAttempt);

        const surveyResponse = [];

        const optionSelected = {};

        for (const [questionId, answerAttempt] of Ids) {
          surveyResponse.push({
            optionId: answerAttempt.optionId,

            option: answerAttempt.option,

            questionId: answerAttempt.questionId,

            question: answerAttempt.question
          });

          optionSelected[answerAttempt.option] = isNaN(
            optionSelected[answerAttempt.option]
          )
            ? 1
            : parseInt(optionSelected[answerAttempt.option]) + 1;
        }

        let answersCount = Object.keys(optionSelected).map(option => ({
          option: option,
          optionCount: optionSelected[option]
        }));

        this.state.finalResultsArr.push({
          TestId: testId,
          UserId: this.props.user.id,
          obtainedMarks,
          maxMarks,
          percentageObtained,
          answersAttempt: surveyResponse,
          answersCount,
          testCount: this.state.testIds.length,
          testIds
        });
      });

      axios
        .post(API_URL + `private/user_assessments`, this.state.finalResultsArr)
        .then(res => {
          this.props.updateUser({}, this.props.user.id, this.props.user);
          this.props.history.replace(`/result/${res.data.data[0].uniqId}`);
        })
        .catch(console.log);
    }

    // if (!final) {
    //   this.setState({
    //     answersAttempt: {}
    //   });
    // }

    //left this for now

    // if (
    //   !this.state.finalResultsArr.length &&
    //   this.props.assessmentQuestion.answersAttempt &&
    //   this.props.assessmentQuestion.answersCount
    // ) {
    // const prevSurveyResponse = JSON.parse(
    //   this.props.assessmentQuestion.answersAttempt
    // );

    // if (prevSurveyResponse.length) {
    //   surveyResponse = [...prevSurveyResponse, ...surveyResponse];

    //   const prevAnswersCount = JSON.parse(
    //     this.props.assessmentQuestion.answersCount
    //   );

    //   const toSliceIndexes = [];
    //   prevAnswersCount.map((prevAnswer, prevIndex) => {
    //     answersCount.map((answer, index) => {
    //       if (prevAnswer.option === answer.option) {
    //         answersCount[index].optionCount += prevAnswer.optionCount;
    //         toSliceIndexes.push(prevIndex);
    //       }
    //     });
    //   });

    //   for (let i = toSliceIndexes.length - 1; i >= 0; i--)
    //     prevAnswersCount.splice(toSliceIndexes[i], 1);

    //   answersCount = [...prevAnswersCount, ...answersCount];
    // }
    // }
  };

  // calculateResults = (e, final, saveForLater) => {
  // if (final) e.target.disabled = true;
  // const { answersAttempt } = this.state;
  // let obtainedMarks, percentageObtained, maxMarks;

  // if (!saveForLater) {
  //   maxMarks = this.state.surveyQuestions[this.state.currentTestIndex].reduce(
  //     (total, question) =>
  //       Math.max(...question.QuestionOptions.map(option => option.marks)) +
  //       total,
  //     0
  //   );

  //   obtainedMarks = this.state.surveyQuestions[
  //     this.state.currentTestIndex
  //   ].reduce(
  //     (total, question) =>
  //       question.QuestionOptions.find(
  //         option => option.id === answersAttempt[question.id].optionId
  //       ).marks + total,

  //     0
  //   );

  //   percentageObtained = ((obtainedMarks / maxMarks) * 100).toFixed(2);
  // }

  // const Ids = Object.entries(answersAttempt);

  // let surveyResponse = [];

  // const optionSelected = {};

  // for (const [questionId, answerAttempt] of Ids) {
  //   surveyResponse.push({
  //     optionId: answerAttempt.optionId,

  //     option: answerAttempt.option,

  //     questionId: answerAttempt.questionId,

  //     question: answerAttempt.question
  //   });

  //   optionSelected[answerAttempt.option] = isNaN(
  //     optionSelected[answerAttempt.option]
  //   )
  //     ? 1
  //     : parseInt(optionSelected[answerAttempt.option]) + 1;
  // }

  // let answersCount = Object.keys(optionSelected).map(option => ({
  //   option: option,
  //   optionCount: optionSelected[option]
  // }));

  // if (
  //   !this.state.finalResultsArr.length &&
  //   this.props.assessmentQuestion.answersAttempt &&
  //   this.props.assessmentQuestion.answersCount
  // ) {
  //   const prevSurveyResponse = JSON.parse(
  //     this.props.assessmentQuestion.answersAttempt
  //   );

  //   if (prevSurveyResponse.length) {
  //     surveyResponse = [...prevSurveyResponse, ...surveyResponse];

  //     const prevAnswersCount = JSON.parse(
  //       this.props.assessmentQuestion.answersCount
  //     );

  //     const toSliceIndexes = [];
  //     prevAnswersCount.map((prevAnswer, prevIndex) => {
  //       answersCount.map((answer, index) => {
  //         if (prevAnswer.option === answer.option) {
  //           answersCount[index].optionCount += prevAnswer.optionCount;
  //           toSliceIndexes.push(prevIndex);
  //         }
  //       });
  //     });

  //     for (let i = toSliceIndexes.length - 1; i >= 0; i--)
  //       prevAnswersCount.splice(toSliceIndexes[i], 1);

  //     answersCount = [...prevAnswersCount, ...answersCount];
  //   }
  // }

  // if (saveForLater) {
  //   answersCount.map((answer, index) => {
  //     if (
  //       answer.option === surveyResponse[surveyResponse.length - 1].option
  //     ) {
  //       if (answersCount[index].optionCount > 1)
  //         answersCount[index].optionCount -= 1;
  //       else answersCount.splice(index, 1);
  //     }
  //   });
  //   surveyResponse.pop();

  //   this.state.finalResultsArr.push({
  //     TestId: this.state.surveyQuestions[this.state.currentTestIndex][0].Test
  //       .id,
  //     UserId: this.props.user.id,

  //     answersAttempt: surveyResponse,

  //     answersCount,
  //     testCount: this.state.surveyQuestions.length
  //   });
  // } else {
  //   this.state.finalResultsArr.push({
  //     TestId: this.state.surveyQuestions[this.state.currentTestIndex][0].Test
  //       .id,
  //     UserId: this.props.user.id,

  //     obtainedMarks,

  //     maxMarks,

  //     percentageObtained,

  //     answersAttempt: surveyResponse,
  //     answersCount,
  //     testCount: this.state.surveyQuestions.length
  //   });

  //   if (!final) {
  //     this.setState({
  //       answersAttempt: {}
  //     });
  //   }
  // }

  // if (final) {
  //   axios
  //     .post(API_URL + `private/user_assessments`, this.state.finalResultsArr)
  //     .then(res =>
  //       this.props.history.replace(`/result/${res.data.data[0].uniqId}`)
  //     )
  //     .catch(err => alert(err.response.data.message));
  // }
  // };

  surveyQuestion = questionIndex => {
    // const questionData = this.state.surveyQuestions[
    //   this.state.currentTestIndex
    // ][questionIndex];
    const questionData = this.state.surveyQuestions[questionIndex];
    questionData.QuestionOptions = questionData.QuestionOptions.filter(
      option => option.isActive
    );

    const answersAttempt = this.state.answersAttempt[questionData.id] || {};

    if (!this.state.answersAttempt.hasOwnProperty(questionData.id)) {
      this.updateSurveyResponse(questionData);
    }

    return (
      <div
        className="category-block"
        // key={
        //   this.state.surveyQuestions[this.state.currentTestIndex][questionIndex]
        //     .id
        // }
        key={this.state.surveyQuestions[questionIndex].id}
      >
        <h1 className="main-heading question">{questionData.question}</h1>

        <ul className="answer">
          {questionData.QuestionOptions.map((option, index) => (
            <li key={option.id}>
              <label className="container1">
                {option.option}

                <input
                  type="radio"
                  checked={answersAttempt.optionId === option.id}
                  onChange={e => this.onChoose(e, questionData, index)}
                  name={option.id}
                />

                <span className="checkmark" />
              </label>
            </li>
          ))}
        </ul>

        <div className="question-btn">
          {this.state.questionIndex >
            0 /*||
             this.state.currentTestIndex > 0) */ && (
            <button className="back cst-back" onClick={this.onPrev} style={{whiteSpace:'nowrap'}}>
              <i className="fa fa-angle-left" aria-hidden="true" />
              Back
            </button>
          )}

          {this.state.questionIndex <
          this.state.surveyQuestions.length -
            1 /* ||
          this.state.currentTestIndex <
            this.state.surveyQuestions.length - 1 */ ? (
            <button className="next cst-next" onClick={this.onNext}>
              Next Question
              <i className="fa fa-angle-right" aria-hidden="true" />
            </button>
          ) : (
            <button
              className="next "
              onClick={e => this.calculateResults(e, false)}
            >
              Submit Survey
              <i className="fa fa-angle-right" aria-hidden="true" />
            </button>
          )}

          {this.state.questionIndex >
            0 /* ||
            this.state.currentTestIndex > 0) */ && (
            <button className="next save_later cst-later" onClick={this.saveForLater}>
              Continue Later
            </button>
          )}
        </div>
      </div>
    );
  };

  showAlert = () => {
    if (
      this.props.assessmentQuestion.answersAttempt &&
      this.props.assessmentQuestion.answersCount &&
      this.state.alertPoppedUp
    ) {
      return (
        <SweetAlert
          success
          title="Continue the survey."
          onConfirm={() => this.setState({ alertPoppedUp: false })}
        >
          Your assessment has been resumed.
        </SweetAlert>
      );
    }
  };

  render() {
    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            {/* {
              <h6 className="question-heading">
                {this.state.surveyQuestions[this.state.questionIndex] &&
                  this.state.surveyQuestions[this.state.questionIndex].Test
                    .name[
                    this.state.surveyQuestions[this.state.questionIndex].Test
                      .name.length - 1
                  ]}{' '}
              </h6>
            } */}
            <br />

            {/*this.state.surveyQuestions[this.state.currentTestIndex] && */
            this.state.surveyQuestions /* [this.state.currentTestIndex] */[
              this.state.questionIndex
            ] && this.surveyQuestion(this.state.questionIndex)}
          </div>
        </div>
        {this.showAlert()}
      </>
    );
  }
}

UserAssessment.propTypes = {
  getQuestions: PropTypes.func.isRequired,

  assessmentQuestion: PropTypes.object.isRequired,

  user: PropTypes.object.isRequired,

  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  error: state.error,

  assessmentQuestion: state.assessmentQuestion.questions,

  user: state.user.user
});

export default connect(
  mapStateToProps,

  {
    getQuestions,
    updateUser
  }
)(UserAssessment);
