import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap-tabs';

import TopHeader from '../layout/header';
import { getLearningContentByUniqueId } from '../../store/actions/LearningContentActions';
import isEmpty from '../../validation/is-empty';

class SurveyResponse extends Component {
  componentDidMount() {
    this.props.getLearningContentByUniqueId(this.props.match.params.uniqId);
  }

  render() {
    const {
      learningContent: { learningContent }
    } = this.props;
    const {
      learningContent: { resultAnalysis }
    } = this.props;

    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h6 className="question-heading question-heading2">
                  Personality Content - Assessment Result
                </h6>
              </div>
            </div>
            {!isEmpty(resultAnalysis) && (
              <>
                <ul className="asem-res-ul">
                  <li>Dominate Trait: {resultAnalysis.mainPersonality}</li>
                  <li>Sub-Dominate Trait:{' '}
                    {resultAnalysis.underlyingPersonality}
                  </li>
                </ul>

                {/* <p className="f-o-p">
                  Please read and watch the videos to better understand your
                  dominant and sub dominant traits.{' '}
                  {resultAnalysis.otherPersonalities.join(', ')}.
                </p> */}
                <hr />
              </>
            )}

            {Array.isArray(learningContent) && !isEmpty(learningContent) ? (
              <Tabs onSelect={(index, label) => {}} selected={!isEmpty(resultAnalysis) && (resultAnalysis.mainPersonality)}>
                {learningContent.map((content, i)  => (
                  <Tab label={content.Test.name} key={content.Test.id} >
                    <div className="row">
                      <div className="col-xs-12 col-md-12 video-text">
                        <p className="tab-content-head">
                          <strong>{content.generalTitle}</strong>
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.generalContent
                          }}
                        />
                      </div>
                      <div
                        className="col-xs-12 col-md-12 video-container"
                        dangerouslySetInnerHTML={{
                          __html: content.generalLink
                        }}
                      />
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-md-12 video-text">
                        <p className="tab-content-head">
                          <strong>{content.coachTitle}</strong>
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.coachContent
                          }}
                        />
                      </div>

                      <div
                        className="col-xs-12 col-md-12 video-container"
                        dangerouslySetInnerHTML={{
                          __html: content.coachLink
                        }}
                      />
                    </div>

                    {((content.playerContent.trim() !== '<p></p>' &&
                      content.playerContent) ||
                      content.playerLink) && (
                      <div className="row">
                        <div className="col-xs-12 col-md-12 video-text">
                          <p className="tab-content-head">
                            <strong>{content.playerTitle}</strong>
                          </p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: content.playerContent
                            }}
                          />
                        </div>

                        <div
                          className="col-xs-12 col-md-12 video-container"
                          dangerouslySetInnerHTML={{
                            __html: content.playerLink
                          }}
                        />
                      </div>
                    )}
                  </Tab>
                ))}
              </Tabs>
            ) : (
              !isEmpty(learningContent) && (
                <>
                  <div className="row">
                    <div className="col-xs-12 col-md-12 video-text">
                      <p className="tab-content-head">
                        <strong>{learningContent.generalTitle}</strong>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: learningContent.generalContent
                        }}
                      />
                    </div>
                    <div
                      className="col-xs-12 col-md-12 video-container"
                      dangerouslySetInnerHTML={{
                        __html: learningContent.generalLink
                      }}
                    />
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-md-12 video-text">
                      <p className="tab-content-head">
                        <strong>{learningContent.coachTitle}</strong>
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: learningContent.coachContent
                        }}
                      />
                    </div>
                    <div
                      className="col-xs-12 col-md-12 video-container"
                      dangerouslySetInnerHTML={{
                        __html: learningContent.coachLink
                      }}
                    />
                  </div>

                  {((learningContent.playerContent.trim() !== '<p></p>' &&
                    learningContent.playerContent) ||
                    learningContent.playerLink) && (
                    <div className="row">
                      <div className="col-xs-12 col-md-12 video-text">
                        <p className="tab-content-head">
                          <strong>{learningContent.playerTitle}</strong>
                        </p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: learningContent.playerContent
                          }}
                        />
                      </div>
                      <div
                        className="col-xs-12 col-md-12 video-container"
                        dangerouslySetInnerHTML={{
                          __html: learningContent.playerLink
                        }}
                      />
                    </div>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  learningContent: state.learningContent
});

export default connect(
  mapStateToProps,
  { getLearningContentByUniqueId }
)(SurveyResponse);
