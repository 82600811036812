import axios from "axios";
import { API_URL } from "../constants/config";
import store from "../store";
import { GET_ERROR } from "../constants/error";

//file uploading to server and get a file url
const fileUpload = (file, directory) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("directory", directory);
    data.append("file", file);
    axios
      .post(API_URL + "private/file_uploads/single", data)
      .then(res => resolve(res.data.filePath))
      .catch(err => {
        store.dispatch({
          type: GET_ERROR,
          payload: err.response.data.message
        });
      });
  });
};

export default fileUpload;
