import axios from 'axios';

import {
  GET_SETTINGS,
  UPDATE_SETTINGS
} from '../../constants/setting';
import { GET_ERROR, CLEAR_ERROR } from '../../constants/error';
import { GET_SUCCESS, CLEAR_SUCCESS } from '../../constants/success';

import { API_URL } from '../../constants/config';

// Get Settings
export const getSettings = (id) => dispatch => {
  dispatch(clearError());
  if (id === undefined) {
    dispatch({ type: GET_SUCCESS, payload: {} });
    return dispatch({
      type: GET_SETTINGS,
      payload: {}
    });
  }

  axios
    .get(API_URL + `public/admin_settings`)
    .then(res => {
      const { data } = res;
      dispatch({ type: GET_SUCCESS, payload: data.status });
      dispatch({ type: GET_SETTINGS, payload: data });
    })
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

export const updateSettings = (updateData) => dispatch => {
  dispatch(clearError());
  dispatch(clearSuccess());
  axios
    .post(API_URL + `private/admin_settings/edit`, updateData)
    .then(res =>
      dispatch({
        type: UPDATE_SETTINGS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Clear error
export const clearError = () => {
  return {
    type: CLEAR_ERROR
  };
};

// Clear success
export const clearSuccess = () => {
  return {
    type: CLEAR_SUCCESS
  };
};