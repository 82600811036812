import React from 'react';

const ChangePassword = ({ form, success, onSubmit, onChange, error }) => {
  return (
    <form noValidate onSubmit={onSubmit}>
      <div className="form-group row">
        {success && (
          <div className="col-xs-12 col-md-12 mb-10 text-center alert alert-info">
            <span>Your password is updated.</span>
          </div>
        )}

        {error && (
          <div
            className="col-xs-12 col-md-12 mb-10 alert alert-danger text-center"
            role="alert"
          >
            {error}
          </div>
        )}
      </div>

      <div className="form-group row">
        <div className="col-xs-12 col-md-3 mb-10">
          <label>Current Password</label>
        </div>
        <div className="col-xs-12 col-md-9 mb-10">
          <input
            type="password"
            className="form-control"
            name="oldPassword"
            onChange={onChange}
            value={form.oldPassword}
            placeholder="Enter Current Password"
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-xs-12 col-md-3 mb-10">
          <label>New Password</label>
        </div>
        <div className="col-xs-12 col-md-9 mb-10">
          <input
            type="password"
            className="form-control"
            name="password"
            onChange={onChange}
            value={form.password}
            placeholder="Enter new password"
          />
        </div>
      </div>

      <div className="form-group row">
        <div className="col-xs-12 col-md-3 mb-10">
          <label>Confirm password</label>
        </div>
        <div className="col-xs-12 col-md-9 mb-10">
          <input
            type="password"
            className="form-control"
            name="confirmPassword"
            onChange={onChange}
            value={form.confirmPassword}
            placeholder="Confirm  password"
          />
        </div>
      </div>
      <div className="login-btm col-md-6 col-sm-12">
        <button type="submit" className="btn custom-btn">
          Update
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
