import axios from 'axios';

import {
  ADD_QUESTION,
  GET_QUESTIONS,
  GET_ADMIN_QUESTIONS,
  GET_QUESTION,
  UPDATE_QUESTION,
  QUESTION_LOADING,
  DELETE_QUESTION,
  GET_QUESTIONS_BY_SURVEY
} from '../../constants/assessment_question';

import { GET_ERROR, CLEAR_ERROR } from '../../constants/error';
import { API_URL } from '../../constants/config';

// Add Question
export const addQuestion = postData => dispatch => {
  dispatch(clearError());
  axios
    .post(API_URL + 'private/questions/new', postData)
    .then(res =>
      dispatch({
        type: ADD_QUESTION,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      });
    });
};

// Update Question
export const updateQuestion = (updateData, id) => dispatch => {
  dispatch(clearError());
  axios
    .patch(API_URL + `private/questions/${id}/edit`, updateData)
    .then(res =>
      dispatch({
        type: UPDATE_QUESTION,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Get Questions
export const getQuestions = (testId, userId) => dispatch => {
  if (testId === undefined) {
    return dispatch({
      type: GET_QUESTIONS,
      payload: { data: [] }
    });
  }

  dispatch(setQuestionLoading());

  axios
    .get(API_URL + 'private/questions/' + testId + '/tests_questions/' + userId)
    .then(res =>
      dispatch({
        type: GET_QUESTIONS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_QUESTIONS,
        payload: err.response.data
      })
    );
};

// Get Questions
export const getQuestionsBySingleTest = testId => dispatch => {
  dispatch(setQuestionLoading());
  axios
    .get(API_URL + 'private/questions/' + testId + '/test_questions')
    .then(res =>
      dispatch({
        type: GET_ADMIN_QUESTIONS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_QUESTIONS,
        payload: err.response.data
      })
    );
};

// Get Question
export const getQuestion = id => dispatch => {
  dispatch(setQuestionLoading());
  if (id === undefined) {
    return dispatch({
      type: GET_QUESTION,
      payload: { data: {} }
    });
  }
  axios
    .get(API_URL + `private/questions/${id}/item`)
    .then(res =>
      dispatch({
        type: GET_QUESTION,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_QUESTION,
        payload: err.response.data
      })
    );
};

// Get Questions
export const getSurveyQuestions = testId => dispatch => {
  dispatch(setQuestionLoading());
  axios
    .get(`${API_URL}private/questions/${testId}/test_questions`)
    .then(res =>
      dispatch({
        type: GET_QUESTIONS_BY_SURVEY,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_QUESTIONS_BY_SURVEY,
        payload: err.response.data
      })
    );
};

// Delete Question
export const deleteQuestion = id => dispatch => {
  axios
    .delete(API_URL + `private/questions/${id}/delete`)
    .then(res =>
      dispatch({
        type: DELETE_QUESTION,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Set loading state
export const setQuestionLoading = () => {
  return {
    type: QUESTION_LOADING
  };
};

// Clear error
export const clearError = () => {
  return {
    type: CLEAR_ERROR
  };
};
