import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TopHeader from '../layout/header';
import ChangePassword from '../user/ChangePassword';
import { updateUser } from '../../store/actions/UserActions';

class ViewProfile extends Component {
  state = {
    form: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    },
    showModal: false
  };

  onChange = e => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  onSubmit = e => {
    e.preventDefault();

    this.props.updateUser(this.state.form, this.props.user.id, this.props.user);
  };

  showModal = e => {
    e.preventDefault();

    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  renderModal = () => {
    return (
      <div className="modal fade show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="question-heading">Change Password</h6>
              <button type="button" className="close" onClick={this.hideModal}>
                &times;
              </button>
            </div>

            <div className="modal-body">
              <ChangePassword
                {...this.state}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                error={this.props.error}
                success={this.props.success && 'You profile is updated.'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { user = {} } = this.props;

    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              <div className="row">
                <table className="table table-striped">
                  <thead />
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {user.firstName} {user.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{user.email}</td>
                    </tr>
                    <tr>
                      <td>Password</td>
                      <td>
                        <span>***********</span>
                        <Link to="#" onClick={this.showModal} className="ml-2">
                          Change Password
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{user.phone}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{user.address}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{user.city}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>{user.state}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{user.country}</td>
                    </tr>
                    <tr>
                      <td>Zip</td>
                      <td>{user.postalCode}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {this.state.showModal === true && this.renderModal()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  error: state.error,
  success: state.success
});

export default connect(
  mapStateToProps,
  { updateUser }
)(ViewProfile);
