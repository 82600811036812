import { GET_ERROR, CLEAR_ERROR } from '../../constants/error';

const initialState = '';

const error = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return '';
    default:
      return state;
  }
};

export default error;
