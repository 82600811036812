import {
  GET_SETTINGS,
  UPDATE_SETTINGS
} from '../../constants/setting';

const initialState = {
  settings: {},
};

const setting = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };

    case UPDATE_SETTINGS:

    default:
      return state;
  }
};

export default setting;
