import {
  ADD_QUESTION,
  UPDATE_QUESTION,
  GET_QUESTIONS,
  GET_ADMIN_QUESTIONS,
  GET_QUESTION,
  QUESTION_LOADING,
  DELETE_QUESTION,
  GET_QUESTIONS_BY_SURVEY
} from '../../constants/assessment_question';

const initialState = {
  questions: [],
  adminQuestions: [],
  question: {},
  SurveyQuestions: [],
  loading: false
};

initialState.questions.questions = [];

const assessmentQuestion = (state = initialState, action) => {
  switch (action.type) {
    case QUESTION_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_ADMIN_QUESTIONS:
      return {
        ...state,
        adminQuestions: action.payload.data.reverse(),
        loading: false
      };
    case GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload.data,
        loading: false
      };
    case GET_QUESTION:
      return {
        ...state,
        question: action.payload.data,
        loading: false
      };

    case GET_QUESTIONS_BY_SURVEY:
      return {
        ...state,
        adminQuestions: action.payload,
        loading: false
      };

    case ADD_QUESTION:
      return {
        ...state,
        adminQuestions: [action.payload.data, ...state.adminQuestions]
      };
    case UPDATE_QUESTION:
      return {
        ...state,
        adminQuestions: state.adminQuestions.map(question =>
          question.id === action.payload.data.id
            ? action.payload.data
            : question
        )
      };

    case DELETE_QUESTION:
      return {
        ...state,
        adminQuestions: state.adminQuestions.filter(
          question => question.id !== action.payload
        )
      };
    default:
      return state;
  }
};

export default assessmentQuestion;
