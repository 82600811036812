import axios from 'axios';

import {
  ADD_LEARNING_CONTENT,
  GET_LEARNING_CONTENTS,
  GET_LEARNING_CONTENT,
  UPDATE_LEARNING_CONTENT,
  LEARNING_CONTENT_LOADING,
  DELETE_LEARNING_CONTENT
} from '../../constants/learning_content';

import { GET_ERROR, CLEAR_ERROR } from '../../constants/error';
import { API_URL } from '../../constants/config';

// Add Learning Content
export const addLearningContent = postData => dispatch => {
  dispatch(clearError());
  axios
    .post(API_URL + 'private/learning_contents/new', postData)
    .then(res =>
      dispatch({
        type: ADD_LEARNING_CONTENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Update Learning Content
export const updateLearningContent = (updateData, id) => dispatch => {
  dispatch(clearError());
  axios
    .patch(API_URL + `private/learning_contents/${id}/edit`, updateData)
    .then(res =>
      dispatch({
        type: UPDATE_LEARNING_CONTENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Get Learning Contents
export const getLearningContents = () => dispatch => {
  dispatch(setLearningContentLoading());
  axios
    .get(API_URL + 'private/learning_contents')
    .then(res =>
      dispatch({
        type: GET_LEARNING_CONTENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_LEARNING_CONTENTS,
        payload: err.response.data
      })
    );
};

// Get Learning Content
export const getLearningContent = id => dispatch => {
  dispatch(setLearningContentLoading());
  if (id === undefined) {
    return dispatch({
      type: GET_LEARNING_CONTENT,
      payload: { data: {} }
    });
  }
  axios
    .get(API_URL + `private/learning_contents/${id}/item`)
    .then(res =>
      dispatch({
        type: GET_LEARNING_CONTENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_LEARNING_CONTENT,
        payload: err.response.data
      })
    );
};

// Get Learning Content By Unique Id
export const getLearningContentByUniqueId = uniqueId => dispatch => {
  dispatch(setLearningContentLoading());
  if (uniqueId === undefined) {
    return dispatch({
      type: GET_LEARNING_CONTENT,
      payload: { data: {} }
    });
  }
  axios
    .get(
      API_URL + `private/learning_contents/${uniqueId}/item/by_result_unique_id`
    )
    .then(res =>
      dispatch({
        type: GET_LEARNING_CONTENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_LEARNING_CONTENT,
        payload: err.response.data
      })
    );
};

// Delete Learning Content
export const deleteLearningContent = id => dispatch => {
  axios
    .delete(API_URL + `private/learning_contents/${id}/delete`)
    .then(() =>
      dispatch({
        type: DELETE_LEARNING_CONTENT,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Set loading state
export const setLearningContentLoading = () => {
  return {
    type: LEARNING_CONTENT_LOADING
  };
};

// Clear error
export const clearError = () => {
  return {
    type: CLEAR_ERROR
  };
};
