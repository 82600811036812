import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Cleave from "cleave.js/react";
import PropTypes from "prop-types";

import { registerUser } from "../../store/actions/UserActions";
import { getSettings } from "../../store/actions/SettingActions";
import { API_URL } from "../../constants/config";
import PaypalBtn from "../common/Paypal";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: ""
      },
      signUpCompleted: false,
      showSecondForm: false,
      surveysNames: [],
      checkedPurchases: new Map(),
      surveyPrices: new Map(),
      allSettings: new Map(),
      allPurchased: false,
      couponCode: "",
      error: "",
      finalPrice: 0,
      errorMessage: "",
      successMessage: "",
      finalPercentage: 0
    };
    this.props.getSettings(true);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.error !== state.error && props.error !== "secondformincomplete") {
      return {
        error: props.error
      };
    }

    if (props.error === "secondformincomplete" && !state.showSecondForm) {
      return {
        showSecondForm: true
      };
    }

    if (props.user.isAuthenticated) {
      props.user.user.roleId === 2
        ? props.history.push("/admin/dashboard")
        : props.history.push("/dashboard");
    }
    return null;
  }

  componentDidMount() {
    axios
      .get(API_URL + "public/tests")
      .then(res =>
        this.setState(
          {
            surveysNames: res.data.data,
            checkedPurchases: new Map([
              ...res.data.data.map(item => [item.id, false])
            ]),
            surveyPrices: new Map([
              ...res.data.data.map(item => [item.id, item.price])
            ])
          },
          () => console.log('price map', this.state.surveyPrices)
        )
      )
      .catch(err => this.setState({ error: "Something went wrong." }));
  }

  onChange = e => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [e.target.name]: e.target.value }
    });
  };

  onPurchaseChange = e => {
    const item = parseInt(e.target.name);
    const isChecked = e.target.checked;
    this.setState(prevState => ({
      checkedPurchases: prevState.checkedPurchases.set(item, isChecked),
      //couponCode: '',
      allPurchased: false
    }));
    this.setState({}, () => {
      this.setState({
        finalPrice: this.handleFinalPrice()
      });
    });
  };

  onAllPurchase = e => {
    const isChecked = e.target.checked;
    this.setState(
      {
        allPurchased: isChecked
      },
      () => {
        this.setState({
          checkedPurchases: new Map(),
          finalPrice: this.handleFinalPrice()
        });
      }
    );
  };

  onPaymentSuccess = payment => {
    let testIds;
    if (!this.state.allPurchased) {
      const optIds = Array.from(this.state.checkedPurchases)
        .filter(val => val[1])
        .map(val => val[0]);

      testIds = optIds.reduce((acc, val) => {
        const testCategory = this.state.surveysNames.find(obj => obj.id === val)
          .testCategory;
        if (testCategory) {
          return acc.concat(
            this.state.surveysNames.reduce(
              (acc, surveyObj) =>
                surveyObj.testCategory === testCategory && surveyObj.id !== val
                  ? acc.concat(surveyObj.id)
                  : acc,
              []
            )
          );
        } else return acc;
      }, optIds);
    } else {
      testIds = this.state.surveysNames.map(obj => obj.id);
    }

    const signUpPayload = {
      ...this.state.form,
      testIds
    };

    this.props.registerUser(signUpPayload, this.changeSignUpState);
  };

  onPaymentCancel = data => {
    this.setState({ error: "Payment has got cancelled. Try again." });
    console.log("The payment was cancelled!", data);
  };

  onPaymentError = err => {
    this.setState({ errorMessage: "Something went wrong. Try again." });
    console.log("Error!", err);
  };

  changeSignUpState = () => {
    this.setState({
      signUpCompleted: !this.state.signUpCompleted
    });
  };

  handleFinalPrice = () => {
    console.log('hr', this.state);
    let price = this.state.allPurchased
      ? this.state.allSettings.get("raspberry_students_price") &&
        this.state.allSettings.get("raspberry_students_price") !== null
        ? Number(this.state.allSettings.get("raspberry_students_price"))
        : 200
      : Array.from(this.state.checkedPurchases)
          .filter(val => val[1])
          .reduce(
            (total, [property]) =>
              total + this.state.surveyPrices.get(property),
            0
          );

    return this.state.finalPercentage > 0
      ? +(((100 - this.state.finalPercentage) / 100) * price).toFixed(2)
      : +price.toFixed(2);
  };

  handleCouponRemove = e => {
    e.preventDefault();
    this.setState(
      {
        finalPercentage: 0,
        couponCode: ""
      },
      () => {
        this.setState({
          finalPrice: this.handleFinalPrice()
        });
      }
    );
    this.setState({ successMessage: "Coupon removed successfully" });
    setTimeout(() => this.setState({ successMessage: "" }), 4000);
  };

  handleCouponApply = e => {
    e.preventDefault();
    if (this.state.couponCode) {
      const couponCodeBody = { code: this.state.couponCode };
      axios
        .post(API_URL + "public/coupon_code/validate-code", couponCodeBody)
        .then(res => {
          if (Object.values(res.data.data).length > 0) {
            this.setState(
              { finalPercentage: Object.values(res.data.data)[0].percentage },
              () => {
                this.setState({
                  finalPrice:
                    this.state.finalPercentage === 100
                      ? 0
                      : this.handleFinalPrice()
                });
              }
            );
          }
          this.setState({ successMessage: "Coupon applied successfully" });
        })
        .catch(err => {
          this.setState({
            successMessage: "",
            finalPercentage: 0,
            errorMessage: err.response.data.message
          });
          setTimeout(() => this.setState({ errorMessage: "" }), 6000);
        });
    } else {
      this.setState({ errorMessage: "Please Enter Coupon Code." });
      setTimeout(() => this.setState({ errorMessage: "" }), 4000);
    }
  };

  onSubmit = e => {
    e.preventDefault();

    if (!(Array.from(this.state.checkedPurchases).filter(val => val[1]).length > 0 || this.state.allPurchased)) {
      this.setState({
        error: "Please select a survey before proceeding."
      });
      setTimeout(() => this.setState({ error: "" }), 5000);
      return;
    }
    this.setState({ errorMessage: "", error: "" });

    this.onPaymentSuccess();
    //console.log("gggg", this.state);
    // const signUpPayload = {
    //   ...this.state.form,
    //   testIds: this.state.surveysNames.map(survey => survey.id)
    // };
    // this.props.registerUser(signUpPayload, this.changeSignUpState);

    // if (this.state.couponCode) {
    //   const couponCodeBody = { code: this.state.couponCode };
    //   const signUpPayload = {
    //     ...this.state.form,
    //     testIds: this.state.surveysNames.map(survey => survey.id)
    //   };
    //   axios
    //     .post(API_URL + 'public/coupon_code/validate-code', couponCodeBody)
    //     .then(() => {
    //       console.log('here');
    //       //this.props.registerUser(signUpPayload, this.changeSignUpState);
    //     })
    //     .catch(() => this.setState({ error: 'Coupon Code is not valid.' }));
    // }
    // setTimeout(() => this.setState({ error: '' }), 5000);
  };

  onNext = () => {
    this.props.registerUser(this.state.form);
  };

  showAlert = () => {
    if (this.state.signUpCompleted) {
      return (
        <SweetAlert
          success
          title="You have successfully signed up."
          onConfirm={this.closeAlert}
        >
          Press OK to login.
        </SweetAlert>
      );
    }
  };

  closeAlert = () => {
    this.setState({ signUpCompleted: false }, () =>
      this.props.history.push("/login")
    );
  };

  render() {

    if (
      Object.keys(this.props.allSettings).length > 0 &&
      "status" in this.props.allSettings
    ) {
      if (
        this.props.allSettings.status &&
        this.props.allSettings.data.length > 0
      ) {
        this.setState({
          ...this.state,
          allSettings: new Map([
            ...this.props.allSettings.data.map(item => [item.slug, item.value])
          ])
        });
        this.props.getSettings();
        //console.log('this.state', this.state);
      }
    }
    const { error } = this.state;
    return (
      <div className="login-bg">
        <section className="login-col">
          <div className="mid-col">
            <div className="inner-col">
              <div className="lft-col">
                <div className="info-data">
                  <div className="logo">
                    {/* <img src="images/logonew.png" alt="FlightScope" /> */}
                  </div>
                  {/* <h2>
                    $
                    {this.state.allSettings.get("golf_price") &&
                    this.state.allSettings.get("golf_price") !== null
                      ? this.state.allSettings.get("golf_price")
                      : 13.95}
                  </h2>
                  <p>
                    Access to the G*O*L*F Performance Assessment Profile Test,
                    Results, and our Video Library for 1 year.
                  </p>
                  <h2>
                    $
                    {this.state.allSettings.get("raspberry_students_price") &&
                    this.state.allSettings.get("raspberry_students_price") !==
                      null
                      ? this.state.allSettings.get("raspberry_students_price")
                      : 200}
                  </h2>
                  <p>
                    Access to all 3 tests, Results, and our Video Library for 1
                    year.
                  </p> */}
                  <br />
                  <h2>Already registered? </h2>
                  <Link
                    to="/login"
                    className="btn custom-btn create-account orange-btn"
                  >
                    Login Account
                    <i className="fa fa-sign-in" />
                  </Link>
                  <br />
                  <br />
                  <small className="copy">
                    © 2019 RPHANALYSIS.COM. All rights reserved
                  </small>
                </div>
              </div>
              <div className="lft-col login">
                <div className="info-data">
                  <div className="logo">
                    <img src="images/logonew.png" alt="RPHAnalysis" />
                  </div>
                  <h2>Create Your Account!</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur molestie tellus non urna porta interdum. Mauris
                    non purus ullamcorper, viverra metus nec, porta ligula.
                  </p>
                  <h2>Lorem ipsum dolor sit amet</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur molestie tellus non urna porta interdum. Mauris
                    non purus ullamcorper, viverra metus nec, porta ligula.
                  </p>
                  <h2>You don’t have an account? </h2>
                  <button className="btn custom-btn login-account orange-btn">
                    Login
                    <i className="fa fa-sign-in" />
                  </button>
                  <br />
                  <br />
                  <small className="copy">
                    © 2019 RPHANALYSIS.COM. All rights reserved
                  </small>
                </div>
              </div>
              <div className="rgt-col SignUpBox">
                <div className="login-content scroll-content ml-auto">
                  <div className="logo logo-mobile">
                    <img src="images/logonew.png" alt="RPHAnalysis" />
                  </div>
                  <h2>SIGN UP</h2>
                  {error && error !== "secondformincomplete" && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  {!this.state.showSecondForm ? (
                    <div className="tab-content">
                      <div role="tabpanel" id="login">
                        <form
                          className="row"
                          noValidate
                          onSubmit={this.onSubmit}
                        >
                          <div className="form-group md-input col-sm-6">
                            <input
                              className="md-form-control"
                              required
                              type="text"
                              name="firstName"
                              value={this.state.form.firstName}
                              onChange={this.onChange}
                            />
                            <span className="highlight" />
                            <i
                              className="fa fa-user custome-envelope"
                              aria-hidden="true"
                            />
                            <label>First Name</label>
                          </div>
                          <div className="form-group md-input col-sm-6">
                            <input
                              className="md-form-control"
                              required
                              type="text"
                              name="lastName"
                              value={this.state.form.lastName}
                              onChange={this.onChange}
                            />
                            <span className="highlight" />
                            <i
                              className="fa fa-user custome-envelope"
                              aria-hidden="true"
                            />
                            <label>Last Name</label>
                          </div>
                          <div className="form-group md-input col-sm-6">
                            <input
                              className="md-form-control"
                              required
                              type="text"
                              name="email"
                              value={this.state.form.email}
                              onChange={this.onChange}
                            />
                            <span className="highlight" />
                            <i
                              className="fa fa-envelope custome-envelope"
                              aria-hidden="true"
                            />
                            <label>Email Address</label>
                          </div>
                          <div className="form-group md-input col-sm-6">
                            <Cleave
                              name="phone"
                              data-state="phone"
                              className="md-form-control"
                              required
                              value={this.state.form.phone}
                              options={{
                                delimiter: "-",
                                blocks: [3, 3, 4]
                              }}
                              onChange={this.onChange}
                            />
                            <span className="highlight" />
                            <i
                              className="fa fa-phone custome-envelope"
                              aria-hidden="true"
                            />
                            <label>Phone</label>
                          </div>
                          <div className="form-group md-input col-sm-6">
                            <input
                              className="md-form-control"
                              required
                              type="password"
                              name="password"
                              value={this.state.form.password}
                              onChange={this.onChange}
                            />
                            <span className="highlight" />
                            <i className="fa fa-key custome-key" />
                            <label>Password</label>
                          </div>
                          <div className="form-group md-input col-sm-6">
                            <input
                              className="md-form-control"
                              required
                              type="password"
                              name="confirmPassword"
                              value={this.state.form.confirmPassword}
                              onChange={this.onChange}
                            />
                            <span className="highlight" />
                            <i className="fa fa-key custome-key" />
                            <label>Confirm Password</label>
                          </div>
                          <div className="login-btm col-sm-12">
                            <button
                              onClick={this.onNext}
                              className="btn custom-btn"
                            >
                              NEXT
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="for-small-device">
                        <h2>Already registered? </h2>
                        <a
                          href="/login"
                          className="btn custom-btn login-account"
                        >
                          Login
                          <i className="fa fa-sign-in" />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="tab-content">
                      <div role="tabpanel" id="login">
                        <h5>
                          Select among the below to complete the signup process.
                        </h5>
                        <form className="row sign-process-block" noValidate
                          onSubmit={this.onSubmit}
                        >
                        <div className="col-sm-6">
                          <div className="form-check">
                            <p className="check-head">Non Raspberry Academy Students</p>
                            {this.state.surveysNames.length &&
                              this.state.surveysNames.map(
                                (survey, index, surveys) => {
                                  if (index > 0)
                                    if (
                                      surveys[index].testCategory &&
                                      surveys[index].testCategory ===
                                        surveys[index - 1].testCategory
                                    )
                                    return;
                                  if (survey.testCategory)
                                    survey.name = survey.testCategory;
                                  return (
                                    <div className="form-check" key={survey.id}>
                                      <input
                                        type="checkbox"
                                        name={survey.id}
                                        id={survey.id}
                                        onChange={this.onPurchaseChange}
                                        className="form-check-input"
                                        checked={this.state.checkedPurchases.get(
                                          survey.id
                                        )}
                                      />
                                      <label
                                        htmlFor={survey.id}
                                        className="form-check-label"
                                      >
                                        {survey.name} (${survey.price})
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                    
                          </div>
                        </div>
                        <div className="col-sm-6">
                         <div className="form-check">
                         <p className="check-head">Raspberry Academy Students</p>
                           {this.state.surveysNames.length && (
                              <div
                                className="form-check"
                                key={
                                  this.state.surveysNames[
                                    this.state.surveysNames.length - 1
                                  ].id + 1
                                }
                              > <input
                                  type="checkbox"
                                  id="allSelect"
                                  name="allSelect"
                                  className="form-check-input"
                                  onChange={this.onAllPurchase}
                                  checked={this.state.allPurchased}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="allSelect"
                                >
                                  All 
                                  {/* ($ {this.state.allSettings.get(
                                    "raspberry_students_price"
                                  ) && this.state.allSettings.get(
                                    "raspberry_students_price"
                                  ) !== null
                                    ? this.state.allSettings.get(
                                        "raspberry_students_price"
                                      )
                                    : 200}
                                  ) */}
                                </label>
                                {this.state.allPurchased && (
                                  <div className="form-group alt-msg col-sm-12">
                                  <p>Please enter the coupon code provided by your instructor.</p>
                                  </div>
                                )}
                              </div>
                            )}
                         </div>
                         </div>
                         {this.state.errorMessage && (
                            <div className="form-group coupn-alt-error col-sm-12">
                            <p>{this.state.errorMessage}</p>
                            </div>
                          )}
                          {this.state.successMessage && (
                            <div className="form-group coupn-alt-success col-sm-12">
                            <p> {this.state.successMessage} </p>
                            </div>
                          )}
                          <div className="col-sm-12 coupon-code-block">           
                          <div className="form-group md-input signup">
                            <input
                              className="md-form-control"
                              type="text"
                              name="couponCode"
                              readOnly={this.state.finalPercentage > 0}
                              value={this.state.couponCode}
                              onChange={e =>
                                this.setState({
                                  couponCode: e.target.value
                                  //checkedPurchases: new Map(),
                                  //allPurchased: false
                                })
                              }
                            />
                            <span className="highlight" />
                            <i className="fa fa-ticket custome-envelope" aria-hidden="true" />
                            <label>Have Coupon Code ? </label>
                           </div> 
                            <div className="code-btn">
                              {this.state.finalPercentage > 0 ? (
                                <button
                                  className="btn custom-btn cpn-aply"
                                  onClick={this.handleCouponRemove}
                                > REMOVE</button>
                              ) : (
                                <button
                                  className="btn custom-btn cpn-aply"
                                  onClick={this.handleCouponApply}
                                > APPLY </button>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            {this.state.finalPrice > 0 ? (
                              <p className="fnl-prc">Final Price: ${this.state.finalPrice}</p>
                            ) : ( "")}
                          </div>
                        <div className="col-sm-6">
                          <div className="login-btm paypal-btn-parent">
                            <button
                              type="submit"
                              className="btn custom-btn submit-btn"
                              //  disabled={this.state.checkedPurchases.size > 0}
                            >
                              {!this.state.showSecondForm ? "NEXT" : "SIGN UP"}
                            </button>
                            {(Array.from(this.state.checkedPurchases).filter(
                              val => val[1]
                            ).length > 0 ||
                              this.state.allPurchased) &&
                            this.state.finalPrice > 0 && ( //<>{console.log('d', this.state.finalPrice > 0)}</>
                                <PaypalBtn
                                  onSuccess={this.onPaymentSuccess}
                                  onError={this.onPaymentError}
                                  onCancel={this.onPaymentCancel}
                                  total={this.state.finalPrice}
                                />
                              )}
                          </div>
                          </div>
                        </form>
                      </div>
                      <div className="for-small-device">
                        <h2>You don’t have an account? </h2>
                        <a href="/login"
                          className="btn custom-btn login-account"
                        > Login <i className="fa fa-sign-in" />
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <div className="social-links">
                  <h2> Contact with us: </h2>
                  <a href={
                      this.state.allSettings.get("facebook_link") &&
                      this.state.allSettings.get("facebook_link") !== null
                        ? this.state.allSettings.get("facebook_link")
                        : "https://www.facebook.com/Thinklowgolow"
                    }
                    target="_blank"
                  > <span className="box-social-links face">
                      <i className="fa fa-facebook-f" />
                    </span>
                  </a>
                  <a href={
                      this.state.allSettings.get("instagram_link") &&
                      this.state.allSettings.get("instagram_link") !== null
                        ? this.state.allSettings.get("instagram_link")
                        : "https://www.instagram.com/think_low_go_low/?hl=en"
                    }
                    target="_blank"
                  ><span className="box-social-links linked">
                      <i className="fa fa-instagram" />
                    </span>
                  </a>
                </div>
                {/* Tab panes */}
              </div>
            </div>
          </div>
        </section>
        {this.showAlert()}
      </div>
    );
  }
}

SignUp.propTypes = {
  registerUser: PropTypes.func.isRequired,
  //getSettings: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  allSettings: state.setting.settings,
  error: state.error
});

export default connect(mapStateToProps, { registerUser, getSettings })(
  withRouter(SignUp)
);
