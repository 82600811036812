import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import TopHeader from '../layout/header';

import {
  addLearningContent,
  updateLearningContent,
  getLearningContents,
  getLearningContent,
  deleteLearningContent
} from '../../store/actions/LearningContentActions';
import { getSurveys } from '../../store/actions/AdminActions';

import { clearError } from '../../store/actions/ErrorActions';
import isEmpty from '../../validation/is-empty';

class LearningContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        startRange: 0,
        endRange: 10,
        generalContent: EditorState.createEmpty(),
        generalTitle: EditorState.createEmpty(),
        generalLink: '',
        coachContent: EditorState.createEmpty(),
        coachTitle: EditorState.createEmpty(),
        coachLink: '',
        playerContent: EditorState.createEmpty(),
        playerTitle: EditorState.createEmpty(),
        playerLink: '',
        TestId: ''
      },
      error: '',
      showModal: false,
      learningContents: [],
      learningContent: {},
      assessmentId: 0
    };
  }

  componentDidMount() {
    this.props.getLearningContents();
    this.props.getSurveys();
    if (
      this.props.location.state.prevPath.includes(
        '/admin/assessment-questions/'
      ) ||
      this.props.location.state.prevPath.includes('/admin/enrolled-users/')
    ) {
      this.setState({
        assessmentId: this.props.location.state.prevParam
      });
    }
  }

  convertToDraft = html => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks);

    return EditorState.createWithContent(contentState);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.error !== state.error) {
      return {
        error: props.error
      };
    }

    if (props.learningContent.learningContents !== state.learningContents) {
      return {
        learningContents: props.learningContent.learningContents,
        showModal: false
      };
    }

    if (props.learningContent.learningContent !== state.learningContent) {
      const convertToDraft = html => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks);

        return EditorState.createWithContent(contentState);
      };

      return {
        form: !isEmpty(props.learningContent.learningContent)
          ? {
              startRange: props.learningContent.learningContent.startRange,
              endRange: props.learningContent.learningContent.endRange,
              generalContent: convertToDraft(
                props.learningContent.learningContent.generalContent
              ),
              generalLink: props.learningContent.learningContent.generalLink,
              coachContent: convertToDraft(
                props.learningContent.learningContent.coachContent
              ),
              coachLink: props.learningContent.learningContent.coachLink,
              playerContent: convertToDraft(
                props.learningContent.learningContent.playerContent
              ),
              playerLink: props.learningContent.learningContent.playerLink,
              TestId: props.learningContent.learningContent.TestId,
              generalTitle: props.learningContent.learningContent.generalTitle,
              coachTitle: props.learningContent.learningContent.coachTitle,
              playerTitle: props.learningContent.learningContent.playerTitle,
            }
          : state.form,
        learningContent: props.learningContent.learningContent
      };
    }

    return null;
  }

  getInputAttributes = (name, placeholder) => {
    return {
      name: name,
      onChange: this.onChange,
      value: this.state.form[name] || '',
      error: this.state.error[name] || '',
      placeholder: placeholder
    };
  };

  onChange = e => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [e.target.name]: e.target.value }
    });
  };

  filterByAssessment = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onEditorStateChange = (editorState, boxName) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [boxName]: editorState
      }
    });
  };

  // onClear = () => {
  //   this.setState(
  //     {
  //       form: {
  //         startRange: 0,
  //         endRange: 10,
  //         generalContent: EditorState.createEmpty(),
  //         generalLink: '',
  //         coachContent: EditorState.createEmpty(),
  //         coachLink: '',
  //         playerContent: EditorState.createEmpty(),
  //         playerLink: ''
  //       },
  //       error: '',
  //       learningContent: {}
  //     },
  //     () => this.props.getLearningContent()
  //   );
  // };

  onSubmit = async () => {
    const form = Object.assign({}, this.state.form);

    form.generalContent = draftToHtml(
      convertToRaw(form.generalContent.getCurrentContent())
    );
    form.coachContent = draftToHtml(
      convertToRaw(form.coachContent.getCurrentContent())
    );
    form.playerContent = draftToHtml(
      convertToRaw(form.playerContent.getCurrentContent())
    );

    form.startRange = Number(form.startRange);
    form.endRange = Number(form.endRange);
    form.TestId = Number(form.TestId);

    isEmpty(this.state.learningContent)
      ? this.props.addLearningContent(form)
      : this.props.updateLearningContent(form, this.state.learningContent.id);
  };

  deleteLearningContent = (e, id) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteLearningContent(id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  showModal = (e, id) => {
    e.preventDefault();
    this.props.getLearningContent(id);
    this.props.clearError();
    const isFromQuestionRoute =
      this.props.location.state.prevPath.includes(
        '/admin/assessment-questions/'
      ) ||
      this.props.location.state.prevPath.includes('/admin/enrolled-users/');

    this.setState({
      showModal: true,
      form: {
        ...this.state.form,
        startRange: 0,
        endRange: 10,
        generalContent: EditorState.createEmpty(),
        generalLink: '',
        generalTitle: '',
        coachContent: EditorState.createEmpty(),
        coachLink: '',
        coachTitle: '',
        playerContent: EditorState.createEmpty(),
        playerLink: '',
        playerTitle: '',
        TestId: isFromQuestionRoute
          ? this.props.location.state.prevParam
          : this.props.admin.surveys[0] && this.props.admin.surveys[0].id
      }
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  renderModal = () => {
    return (
      <div className="modal fade show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="question-heading">
                {isEmpty(this.state.learningContent)
                  ? 'Add Learning Content'
                  : 'Update Learning Content'}
              </h6>
              <button type="button" className="close" onClick={this.hideModal}>
                &times;
              </button>
            </div>

            {this.state.error && (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            )}

            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label htmlFor="" className="question mb-5">
                      Survey Assessment
                    </label>
                    <br />
                    <select
                      className="form-control"
                      id="exampleSelect1"
                      name="TestId"
                      onChange={this.onChange}
                      value={this.state.form.TestId}
                    >
                      {this.props.admin.surveys.map(survey => (
                        <option value={survey.id} key={survey.id}>
                          {survey.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="" className="question mb-5">
                      Start Range
                    </label>
                    <br />
                    <select
                      className="form-control"
                      id="exampleSelect2"
                      name="startRange"
                      onChange={this.onChange}
                      value={this.state.form.startRange}
                    >
                      <option value="0">0%</option>
                      <option value="10">10%</option>
                      <option value="20">20%</option>
                      <option value="30">30%</option>
                      <option value="40">40%</option>
                      <option value="50">50%</option>
                      <option value="60">60%</option>
                      <option value="70">70%</option>
                      <option value="80">80%</option>
                      <option value="90">90%</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="" className="question mb-5">
                      End Range
                    </label>
                    <br />
                    <select
                      className="form-control"
                      id="exampleSelect2"
                      name="endRange"
                      onChange={this.onChange}
                      value={this.state.form.endRange}
                    >
                      <option value="10">10%</option>
                      <option value="20">20%</option>
                      <option value="30">30%</option>
                      <option value="40">40%</option>
                      <option value="50">50%</option>
                      <option value="60">60%</option>
                      <option value="70">70%</option>
                      <option value="80">80%</option>
                      <option value="90">90%</option>
                      <option value="100">100%</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <input name="generalTitle" onChange={this.onChange} value={this.state.form.generalTitle} placeholder="Golfologist's Content"/>
                    <br />
                    <div className="text-editior mb-2">
                      <Editor
                        editorState={this.state.form.generalContent}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="textEditorWrapper"
                        editorClassName="textEditor"
                        onEditorStateChange={editorState =>
                          this.onEditorStateChange(
                            editorState,
                            'generalContent'
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <br />
                    <label htmlFor="" className="question mb-5">
                      Golfologist's Link
                    </label>
                    <br />
                    <input
                      type="text"
                      className="form-control font"
                      {...this.getInputAttributes(
                        'generalLink',
                        'Enter the general link'
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <input name="coachTitle" onChange={this.onChange} value={this.state.form.coachTitle} placeholder="Coach Content"/>
                    <br />
                    <div className="text-editior mb-2">
                      <Editor
                        editorState={this.state.form.coachContent}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="textEditorWrapper"
                        editorClassName="textEditor"
                        onEditorStateChange={editorState =>
                          this.onEditorStateChange(editorState, 'coachContent')
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <br />
                    <label htmlFor="" className="question mb-5">
                      Coach Link
                    </label>
                    <br />
                    <input
                      type="text"
                      className="form-control font"
                      {...this.getInputAttributes(
                        'coachLink',
                        'Enter the coach link'
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <input name="playerTitle" onChange={this.onChange} value={this.state.form.playerTitle} placeholder="Player Content"/>
                    <br />
                    <div className="text-editior mb-2 ">
                      <Editor
                        editorState={this.state.form.playerContent}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="textEditorWrapper"
                        editorClassName="textEditor"
                        onEditorStateChange={editorState =>
                          this.onEditorStateChange(editorState, 'playerContent')
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <br />
                    <label htmlFor="" className="question mb-5">
                      Player Link
                    </label>
                    <br />
                    <input
                      type="text"
                      className="form-control font"
                      {...this.getInputAttributes(
                        'playerLink',
                        'Enter the player link'
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="question-btn question-btn1 mt-20">
                {/* <button className="back" onClick={this.onClear}>
                    Clear
                  </button> */}
                <button className="next" onClick={this.onSubmit}>
                  {isEmpty(this.state.question) ? 'Save ' : 'Update '}
                </button>
              </div>
            </div>
            <div className="modal-footer" />
          </div>
        </div>
      </div>
    );
  };

  renderLearningContentsList = learningContent => {
    if (!learningContent.Test) return;
    return (
      <tr key={learningContent.id}>
        <td>{learningContent.Test.name}</td>
        <td>
          {learningContent.startRange}% - {learningContent.endRange}%
        </td>
        <td>
          <div className="action-btn">
            <span
              className="edit"
              href="#"
              onClick={e => this.showModal(e, learningContent.id)}
            >
              <i className="fa fa-edit" />
            </span>
            <span
              className="delete"
              href="#"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Delete"
              onClick={e => this.deleteLearningContent(e, learningContent.id)}
            >
              <i className="fa fa-trash" />
            </span>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              <h1 className="main-heading">
                Learning Content List (By Assessment)
                {
                  <button
                    type="button"
                    className="btn custom-btn add-btn"
                    onClick={this.showModal}
                  >
                    Add Learning Content
                  </button>
                }
              </h1>
              <div className="clearfix" />
              <div className="row mb-4">
                <div className="col-sm-3">Filter By Assessment</div>
                <div className="col-sm-4">
                  <select
                    className="form-control"
                    name="assessmentId"
                    onChange={this.filterByAssessment}
                    value={this.state.assessmentId}
                  >
                    <option value={0} key={0}>
                      Choose Survey Assessment
                    </option>
                    {this.props.admin.surveys.map(survey => (
                      <option value={survey.id} key={survey.id}>
                        {survey.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row content-list-table">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>Assessment</th>
                      <th>Limit</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.learningContents
                      .filter(learningContent => {
                        if (this.state.assessmentId === 0) return true;
                        else
                          return (
                            learningContent.TestId === this.state.assessmentId
                          );
                      })
                      .map(learningContent =>
                        this.renderLearningContentsList(learningContent)
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal === true && this.renderModal()}
      </>
    );
  }
}

LearningContent.propTypes = {
  getLearningContent: PropTypes.func.isRequired,
  addLearningContent: PropTypes.func.isRequired,
  updateLearningContent: PropTypes.func.isRequired,
  getLearningContents: PropTypes.func.isRequired,
  deleteLearningContent: PropTypes.func.isRequired,
  getSurveys: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
  learningContent: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  error: state.error,
  admin: state.admin,
  learningContent: state.learningContent
});

export default connect(
  mapStateToProps,
  {
    getLearningContent,
    addLearningContent,
    updateLearningContent,
    getLearningContents,
    deleteLearningContent,
    getSurveys,
    clearError
  }
)(LearningContent);
