import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import TopHeader from '../layout/header';
import {
  addSurvey,
  updateSurvey,
  getSurveys,
  getSingleSurvey,
  deleteSurvey
} from '../../store/actions/AdminActions';
import { clearError } from '../../store/actions/ErrorActions';

import fileUpload from '../../utils/fileUpload';
import { API_URL } from '../../constants/config';
import isEmpty from '../../validation/is-empty';

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        description: '',
        imagePath: '',
        testCategory: '',
        price: ''
      },
      error: '',
      showEditModal: false,
      surveys: [],
      survey: {},
      crop: {
        unit: 'px',
        width: 132,
        aspect: 10 / 9
      },
      src: null
    };
  }

  componentDidMount() {
    this.props.getSurveys();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.error !== state.error) {
      return {
        error: props.error
      };
    }

    if (props.admin.surveys !== state.surveys) {
      return {
        surveys: props.admin.surveys,
        showEditModal: false
      };
    }

    if (props.admin.survey !== state.survey) {
      return {
        form: !isEmpty(props.admin.survey)
          ? {
              name: props.admin.survey.name,
              description: props.admin.survey.description,
              imagePath: props.admin.survey.imagePath,
              testCategory: props.admin.survey.testCategory,
              price: props.admin.survey.price,
            }
          : state.form,
        survey: props.admin.survey
      };
    }

    return null;
  }

  getInputAttributes = (name, placeholder) => {
    return {
      name: name,
      onChange: this.handleChange,
      value: this.state.formInputs[name] || '',
      error: this.state.error[name] || '',
      placeholder: placeholder
    };
  };

  onChange = e => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, [e.target.name]: e.target.value }
    });
  };

  onFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onSubmit = async (e, id) => {
    e.preventDefault();
    const { form } = this.state;

    if (this.state.croppedImageUrl) {
      const blob = await fetch(this.state.croppedImageUrl).then(r => r.blob());

      const filePath = await fileUpload(blob, 'survey');
      form.imagePath = filePath;
    } else if (this.state.src) {
      const block = this.state.src.split(';');
      const contentType = block[0].split(':')[1];
      const realData = block[1].split(',')[1];

      const filePath = await fileUpload(
        this.b64toBlob(realData, contentType),
        'survey'
      );
      form.imagePath = filePath;
    }

    isEmpty(this.state.survey)
      ? this.props.addSurvey(form)
      : this.props.updateSurvey(form, id);
  };

  deleteSurvey = (e, id) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteSurvey(id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  showModal = (e, id) => {
    e.preventDefault();
    this.props.getSingleSurvey(id);
    this.props.clearError();
    this.setState({
      src: '',
      showEditModal: true,
      form: {
        ...this.state.form,
        name: '',
        description: '',
        imagePath: '',
        testCategory: '',
        price: ''
      },
      crop: {
        unit: 'px',
        width: 132,
        aspect: 10 / 9
      },
      croppedImageUrl: ''
    });
  };

  hideModal = () => {
    this.setState({
      showEditModal: false
    });
  };

  renderSurveyListCard = survey => {
    return (
      <li key={survey.id}>
        <div className="category-list" style={{backgroundColor: '#fff'}}>
          <h3>{survey.name}</h3>
          <img
            src={API_URL + survey.imagePath}
            alt="Survey name"
            className="surveyImage"
          />
          <div className="category-icons">
            <span
              className="edit"
              data-tip="Edit Survey"
              onClick={e => this.showModal(e, survey.id)}
            >
              <i className="fa fa-edit" />
            </span>
            <span
              className="delete"
              data-tip="Delete Survey"
              onClick={e => this.deleteSurvey(e, survey.id)}
            >
              <i className="fa fa-trash-o" />
            </span>
            <Link
              className="list"
              to={`/admin/assessment-questions/${survey.id}`}
            >
              <i className="fa fa-list" data-tip="Survey Questions" />
            </Link>
            <Link className="group" to={`/admin/enrolled-users/${survey.id}`}>
              <i className="fa fa-group" data-tip="Enrolled Users" />
            </Link>
          </div>
        </div>
      </li>
    );
  };

  renderAddSurveyCard = () => {
    return (
      <li>
        <div className="category-list border-0">
          <h3 className="mb-4">Add Survey</h3>
          <span onClick={this.showModal}>
            <img
              src="/images/addplus5.png"
              alt="Survey name"
              className="surveyAddImage"
            />
          </span>
        </div>
      </li>
    );
  };

  renderModal = () => {
    return (
      <div className="modal fade show" id="myModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="question-heading">
                {isEmpty(this.state.survey)
                  ? 'Add Survey'
                  : `Update Survey - ${this.state.survey.name}`}
              </h6>

              <button type="button" className="close" onClick={this.hideModal}>
                &times;
              </button>
            </div>
            {this.state.error && (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            )}

            <div className="modal-body add-servey-popup">
              <form
                className=""
                noValidate
                onSubmit={e => this.onSubmit(e, this.state.survey.id)}
              >
                <div className="form-group row">
                  <div className="col-xs-12 col-md-4 mb-10 text-right">
                    <label>Survey Name</label>
                  </div>
                  <div className="col-xs-12 col-md-8 mb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={this.onChange}
                      value={this.state.form.name}
                      placeholder="Enter Survey Name"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-md-4 mb-10 text-right">
                    <label>Survey Description</label>
                  </div>
                  <div className="col-xs-12 col-md-8 mb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      onChange={this.onChange}
                      value={this.state.form.description}
                      placeholder="Enter Survey Description"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-md-4 mb-10 text-right">
                    <label>Survey Image</label>
                  </div>
                  <div className="col-xs-12 col-md-8 mb-10">
                    <input
                      type="file"
                      className="form-control"
                      name="imagePath"
                      onChange={this.onFileChange}
                    />
                  </div>
                </div>

                {this.state.src && (
                  <ReactCrop
                    src={this.state.src}
                    crop={this.state.crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}

                <div className="form-group row">
                  <div className="col-xs-12 col-md-4 mb-10 text-right">
                    <label>Survey Category</label>
                  </div>
                  <div className="col-xs-12 col-md-8 mb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="testCategory"
                      onChange={this.onChange}
                      value={this.state.form.testCategory}
                      placeholder="Enter Survey Category"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-xs-12 col-md-4 mb-10 text-right">
                    <label>Price</label>
                  </div>
                  <div className="col-xs-12 col-md-8 mb-10">
                    <input
                      type="text"
                      className="form-control"
                      name="price"
                      onChange={this.onChange}
                      value={this.state.form.price}
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
                <div className="login-btm col-md-6 col-sm-12">
                  <button type="submit" className="btn custom-btn">
                    {isEmpty(this.state.survey) ? 'Submit' : 'Update'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <TopHeader />

        <div className="wrapper">
          <div className="container">
            <div className="category-block">
              <h1 className="main-heading">Survey List</h1>
              
              <div className="row">
                <ul className="thumb-list" style={{backgroundImage: "url('/images/survey-app-background.jpg')"}}>
                  {this.renderAddSurveyCard()}
                  {this.state.surveys &&
                    this.state.surveys.map(survey =>
                      this.renderSurveyListCard(survey)
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {this.state.showEditModal === true && this.renderModal()}
        <ReactTooltip />
      </>
    );
  }
}

Assessment.propTypes = {
  addSurvey: PropTypes.func.isRequired,
  updateSurvey: PropTypes.func.isRequired,
  getSurveys: PropTypes.func.isRequired,
  getSingleSurvey: PropTypes.func.isRequired,
  deleteSurvey: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  error: state.error,
  admin: state.admin
});

export default connect(
  mapStateToProps,
  {
    addSurvey,
    updateSurvey,
    getSurveys,
    getSingleSurvey,
    deleteSurvey,
    clearError
  }
)(Assessment);
