import axios from 'axios';

import {
  ADD_SURVEY,
  GET_SURVEYS,
  GET_SURVEY,
  UPDATE_SURVEY,
  SURVEY_LOADING,
  DELETE_SURVEY
} from '../../constants/admin';

import { GET_ERROR, CLEAR_ERROR } from '../../constants/error';
import { API_URL } from '../../constants/config';

// Add Survey
export const addSurvey = postData => dispatch => {
  dispatch(clearError());
  axios
    .post(API_URL + 'private/tests/new', postData)
    .then(res =>
      dispatch({
        type: ADD_SURVEY,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Update Survey
export const updateSurvey = (updateData, id) => dispatch => {
  dispatch(clearError());
  axios
    .patch(API_URL + `private/tests/${id}/edit`, updateData)
    .then(res =>
      dispatch({
        type: UPDATE_SURVEY,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Get Surveys
export const getSurveys = () => dispatch => {
  dispatch(setSurveyLoading());
  axios
    .get(API_URL + 'private/tests')
    .then(res =>
      dispatch({
        type: GET_SURVEYS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SURVEYS,
        payload: err.response.data
      })
    );
};

// Get Surveys By User
export const getSurveysByUser = userId => dispatch => {
  dispatch(setSurveyLoading());
  axios
    .get(API_URL + 'private/tests/' + userId)
    .then(res =>
      dispatch({
        type: GET_SURVEYS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SURVEYS,
        payload: err.response.data
      })
    );
};

// Get Survey
export const getSurvey = id => dispatch => {
  dispatch(setSurveyLoading());
  if (id === undefined) {
    return dispatch({
      type: GET_SURVEY,
      payload: { data: {} }
    });
  }
  axios
    .get(API_URL + `private/tests/${id}/items`)
    .then(res =>
      dispatch({
        type: GET_SURVEY,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SURVEY,
        payload: err.response.data
      })
    );
};

// Get Single Survey
export const getSingleSurvey = id => dispatch => {
  dispatch(setSurveyLoading());
  if (id === undefined) {
    return dispatch({
      type: GET_SURVEY,
      payload: { data: {} }
    });
  }
  axios
    .get(API_URL + `private/tests/${id}/item`)
    .then(res =>
      dispatch({
        type: GET_SURVEY,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_SURVEY,
        payload: err.response.data
      })
    );
};

// Delete Survey
export const deleteSurvey = id => dispatch => {
  axios
    .delete(API_URL + `private/tests/${id}/delete`)
    .then(res =>
      dispatch({
        type: DELETE_SURVEY,
        payload: id
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERROR,
        payload: err.response.data.message
      })
    );
};

// Set loading state
export const setSurveyLoading = () => {
  return {
    type: SURVEY_LOADING
  };
};

// Clear error
export const clearError = () => {
  return {
    type: CLEAR_ERROR
  };
};
