import React, { useRef, useState, useEffect } from 'react';
import { connect } from "react-redux";
import TopHeader from '../layout/header';

/*  import Designing Constant */
import FeatherIcon from "feather-icons-react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";

/*  import consumable API functions */
import {
  getSettings,
  updateSettings
} from '../../store/actions/SettingActions';

const AdminSettings = (props) => {
  const [message, setMessage] = useState("");
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const errorStyle = {
    color: 'red',
    fontSize: '13px',
  };

  function validate(values){
    let errors = {};
    if ( values.golf_price === undefined || values.golf_price === "") {
      errors.golf_price = 'GOLF Price is required.';
    } else if (values.golf_price && !/^[+-]?([0-9]*[.])?[0-9]+$/.test(values.golf_price)) {
      errors.golf_price = 'GOLF Price is invalid.';
    } 

    if ( values.raspberry_students_price === undefined || values.raspberry_students_price === "") {
      errors.raspberry_students_price = 'Raspberry Price is required.';
    } else if (values.raspberry_students_price && !/^[+-]?([0-9]*[.])?[0-9]+$/.test(values.raspberry_students_price)) {
      errors.raspberry_students_price = 'Raspberry Price is invalid.';
    }

    if (values.facebook_link === undefined || values.facebook_link === "") {
      errors.facebook_link = 'Facebook Link is required.';
    }
    if (values.instagram_link === undefined || values.instagram_link === "") {
      errors.instagram_link = 'Instagram Link is required.';
    }
    return errors;
  }

  // handleSubmit method is used to handle the form submitation.
  function handleSubmit(e){
    if (e) e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  }

  const handleChange = (event) => {
    event.preventDefault();
    setValues({ ...values, [event.target.name]:event.target.value});
  }

  useEffect( () => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.updateSettings(
        values
      );
      setMessage('Settings has been updated successfully');
      setTimeout(() => setMessage(''), 5000);
    } else {
      setIsSubmitting(false);
    }
  }, [errors, isSubmitting]);

  useEffect(() => {
    props.getSettings(true);
  }, []);

  useEffect(() => {
    if (
      Object.keys(props.allSettings).length > 0 &&
      "status" in props.allSettings
    ) {
      if (
        props.allSettings.status &&
        props.allSettings.data.length > 0
      ) {
        setValues(props.allSettings.data.map((key) => {
          return {[key.slug]: key.value};
        }).reduce(function(result, item) {
          let key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        }, {}));
        props.getSettings();
      }
    }
  }, [props.allSettings])

  return (
    <>
      <TopHeader />
      <div className="wrapper">
        <div className="container">
        {message && (<div className="alert alert-success" role="alert">
        {message}
      </div>)}
        <Form id="Discount" onSubmit={handleSubmit}>
          <Row>
            <Col sm={8}>
              <FormGroup row>
                <Label className="main-heading" for="golf_price" sm={3}>
                  GOLF Price
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="golf_price"
                    data-state="golf_price"
                    placeholder="About Us"
                    value={values.golf_price || ''}
                    onChange={handleChange}
                    />
                  {errors.golf_price && ( <p style={errorStyle}>{errors.golf_price}</p>)}
                </Col>
              </FormGroup>
              
              <FormGroup row>
                <Label className="main-heading" for="raspberry_students_price" sm={3}>
                Raspberry Price
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="raspberry_students_price"
                    data-state="raspberry_students_price"
                    placeholder="Raspberry students price"
                    value={values.raspberry_students_price || ''}
                    onChange={handleChange}
                    />
                  {errors.raspberry_students_price && ( <p style={errorStyle}>{errors.raspberry_students_price}</p>)}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label className="main-heading" for="facebook_link" sm={3}>
                Facebook Link
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="facebook_link"
                    data-state="facebook_link"
                    placeholder="Facebook Link"
                    value={values.facebook_link || ''}
                    onChange={handleChange}
                    />
                  {errors.facebook_link && ( <p style={errorStyle}>{errors.facebook_link}</p>)}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label className="main-heading" for="instagram_link" sm={3}>
                Instagram Link
                </Label>
                <Col sm={9}>
                  <Input
                    type="textarea"
                    name="instagram_link"
                    data-state="instagram_link"
                    placeholder="Instagram Link"
                    value={values.instagram_link || ''}
                    onChange={handleChange}
                    />
                  {errors.instagram_link && ( <p style={errorStyle}>{errors.instagram_link}</p>)}
                </Col>
              </FormGroup>
            
              <FormGroup row>
                <Col sm={12}>
                  <Button
                    color="info btn-auto"
                    className="ml-2 float-right"
                    onClick={handleSubmit}
                  >
                    <FeatherIcon icon="save" size="20" className="mb-1" />{" "}
                    Save
                  </Button>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    allSettings: state.setting.settings,
    UpdateSettings: state.UpdateSettings
  };
};

export default connect(
  mapStateToProps,
  { getSettings, updateSettings }
)(AdminSettings);